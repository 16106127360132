import React from 'react'
import Navbar from '../navbar/navbar';
import './working.css';
import Footer from '../footer/Footer';

function Working() {
  return (
    <div>
        <Navbar/>
        <div className="working-title-container">
          <h1 className='working-title'>Translation in progress...  <br/> Suggestions gratefully accepted.</h1>
          {/* <img  className="img-working" src="img/working.svg"/> */}
          <p>See “contact author” below.</p>
        </div>
        <Footer/>         
    </div>
  )
}

export default Working







// import React from 'react';
// import '../addReviewBtn/modal.css';
// import DeleteReview from '../addReviewBtn/deleteReview';
// import { format } from 'timeago.js';
// import { useState, useEffect } from 'react';
// import { db } from '../../firebase/base';
// import { getDoc, doc } from 'firebase/firestore';

// const convertMonth = (month) => {
//     if( month === 1 ) return 'January';
//     else if( month === 2 ) return 'Feburary';
//     else if( month === 3 ) return 'March';
//     else if( month === 4 ) return 'April';
//     else if( month === 5 ) return 'May';
//     else if( month === 6 ) return 'June';
//     else if( month === 7 ) return 'July';
//     else if( month === 8 ) return 'August';
//     else if( month === 9 ) return 'September';
//     else if( month === 10 ) return 'October';
//     else if( month === 11 ) return 'November';
//     else if( month === 12 ) return 'December';
// }

// function DisplayComments({reviewsDataArray, ip, id, archive, user}) {

//     const reviewsContainer = [];
//     const dataRef = doc(db, 'bookReviews', `book-${id}`);
//     const [comments, setComments] = useState([]);
//     useEffect(() => {
//         var data = null;
//         const fetch = async () => {
//             data = await getDoc(dataRef);
//             console.log(data.data());
//             data.data().forEach((comment) => {
//                 comment.reviewContent.forEach((content) => {
//                     content.reviews.forEach((item) => reviewsContainer.push({
//                         item: item,
//                         canDelete: content.ip === ip ? true : false || user ? true : false
//                     }))
//                 })
//             })
//         };
//         fetch();
//     }, []);

//     const [ago, setAgo] = useState(true);

//     const handleChangeDate = (ago, index) => {
//         if(ago) {
//             document.getElementById('review-date-container-normal-'+id+'-'+index).style.display = 'none';
//             document.getElementById('review-date-container-ago-'+id+'-'+index).style.display = 'flex';
//         }
//         else {
//             document.getElementById('review-date-container-normal-'+id+'-'+index).style.display = 'flex';
//             document.getElementById('review-date-container-ago-'+id+'-'+index).style.display = 'none';
//         }
//     }


    
    
    
//     const closeDelete = () => {
//         const containers = document.querySelectorAll('.review-card');
//         containers.forEach((item) => item.style.border = 'none');
//         document.querySelector('.review-delete-container').style.display = 'none';
//         document.getElementById('review-delete-btn').style.display = 'none';
//         document.getElementById('review-delete-btn').outerHTML = document.getElementById('review-delete-btn').outerHTML; /* removes all event listener */ 
//         document.getElementById('review-undo-btn').style.display = 'none';
//     }   

//     if(!reviewsDataArray) return null;
    

  
    
//     console.log(reviewsContainer);
//     var circleClass = '';
//     if(id === 'intro1' || id === 'intro2') circleClass = 'circle-btn';

    
//   return (
//     <div>
//         <div className='modal-content'>
//             {reviewsContainer.map((review) => {
//                 console.log(review);
//                 return (
//                     <div className='review-card' id={'review-card-container-ago-' + id + '-' + reviewsContainer.indexOf(review)} key={Math.random()}>
//                         <div className='review-card-top'>
//                             <div className='review-card-info'>
//                                 <div className='review-card-name'><p>{review.item.name}</p></div>
//                                 <div className='review-date-container-ago' id={'review-date-container-ago-' + id + '-' + reviewsContainer.indexOf(review)} onClick={() => handleChangeDate(false, reviewsContainer.indexOf(review))}>
//                                     <span className='review-year'>• {format(`${review.item.date.year}-${review.item.date.month}-${review.item.date.date} ${review.item.date.hour}:${review.item.date.minute}`)}</span>
//                                 </div> 
//                                 <div className='review-date-container-normal' id={'review-date-container-normal-' + id + '-' + reviewsContainer.indexOf(review)} onClick={() => handleChangeDate(true, reviewsContainer.indexOf(review))}>
//                                     <p className='review-year'>• {review.item.date.year}</p>
//                                     <p className='review-month'> {convertMonth(review.item.date.month)}</p>
//                                     <p className='review-date'> {review.item.date.date}</p>
//                                 </div> 
//                             </div>    
//                         </div>
//                         <div className='review-card-body'>
//                             <p key={Math.random()} className='review-p'>{review.item.review}</p>
//                             <div className='review-card-delete'>
//                                 {review.canDelete ? <DeleteReview containerID={`${'review-card-container-ago-' + id + '-' + reviewsContainer.indexOf(review)}`} archive={archive} reviewContent={reviewsDataArray} id={id} review={review.item.review} key={Math.random()}>Delete</DeleteReview> : <></>}    
//                             </div>
//                         </div>
//                     </div>
//                 )
//             })}
//         </div>
//         <div className='review-delete-container'>
//             <div className={`review-delete-card ${circleClass}`}>
//                 <button className='modal-undo-btn' id='review-undo-btn' style={{display: 'none'}} onClick={closeDelete}>Undo</button>
//                 <button onClick={closeDelete} className='modal-delete-btn' id='review-delete-btn' style={{display: 'none'}} >Delete</button>
//             </div>
//         </div>
//     </div>
//   )
// }

// export default DisplayComments