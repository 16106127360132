import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

export function toMonthName(monthNumber) {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString("en-US", {
    month: "long",
  });
}

TimeAgo.addDefaultLocale(en);
export const timeAgo = new TimeAgo("en-US");
