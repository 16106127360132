import Footer from "../footer/Footer";
import Navbar from "../navbar/navbar";
import "./home.css";
const Home = () => {
  return (
    <div className="home-contaienr">
      <Navbar></Navbar>

      <div className="h-fit min-h-[40em] w-screen flex flex-col justify-center items-center pt-[5em] px-5 lg:px-[15rem]">
        <div className="flex items-center justify-between border-b-2 pb-6 w-full">
          <p className="text-[1.8em] text-themeBlue font-semibold font-serif">
            About the Website
          </p>
          <p className="font-normal font-serif text-[1.1em]">Dan Shine</p>
        </div>
        <p className="font-normal font-serif text-[1.1em] leading-[2em] my-8  px-5">
          This site contains the text and a new verse translation, as it
          progresses, of De Bello Civili. Also included is a history of English
          verse translations since Christopher Marlowe’s was published in 1600.
        </p>
        <p className="font-normal font-serif text-[1.1em] leading-[2em] mb-8  px-5">
          A button on the right of each line invites the reader to offer
          alternate readings or comments. This feature is built so that many of
          these can be added to each or several lines.
        </p> 
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Home;
