import { useEffect } from "react";

/**
 * @param mainRef // ignore click event for this element
 * @param action // this action gets triggered then user clicks outside of mainRef
 * @param optionalRef // ignore click event for this element (Optional)
 */
export function useOutsideClickDetector(mainRef, action, optionalRef) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (!optionalRef) {
        if (mainRef.current && !mainRef.current.contains(event.target)) {
          action();
        }
      } else {
        if (
          mainRef.current &&
          optionalRef.current &&
          !mainRef.current.contains(event.target) &&
          !optionalRef.current.contains(event.target)
        ) {
          action();
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mainRef, optionalRef, action]);
}
