import { collection, getDocs } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as Arrow } from "../../assets/arrow.svg";
import DOC from "../../assets/img/doc.png";
import PDF from "../../assets/img/pdf.png";
import RTF from "../../assets/img/rtf.png";
import TXT from "../../assets/img/txt.png";
import { db } from "../../firebase/base";
import { timeAgo, toMonthName } from "../../utils/commonFunctions";
import { useOutsideClickDetector } from "../../utils/customHooks";
import Footer from "../footer/Footer";
import Navbar from "../navbar/navbar";
import { FileParser } from "./FileParser";
import getLink from "./linkGenerator";
import { ReactComponent as Loading } from "../../assets/loading.svg";

const Download = () => {
  const [bookData, setBookData] = useState(null);
  const [bookOpen, setBookOpen] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);
  const bookDropdownRef = useRef(null);
  const bookItem = useRef(null);

  const [bookContentOpen, setBookContentOpen] = useState(false);
  const [selectedBookContent, setSelectedBookContent] = useState(null);
  const bookContentDropdownRef = useRef(null);
  const bookContentItem = useRef(null);

  const [fileTypeOpen, setfileTypeOpen] = useState(false);
  const [selectedfileType, setSelectedfileType] = useState(null);
  const fileTypeDropdownRef = useRef(null);
  const fileTypeItem = useRef(null);
  const [downloadReady, setDownloadReady] = useState(false);

  var collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  });

  const fetchBookData = async () => {
    let bookOptions = {};
    bookOptions["This Translation"] = "";
    bookOptions["Lucan in English"] = "";
    const querySnapshot = await getDocs(collection(db, "booksLastEditedAt"));
    querySnapshot.forEach((doc) => {
      bookOptions[doc.id] = doc.data().date;
    });
    return Promise.resolve(bookOptions);
  };

  const contentOptions = ["Latin", "English", "Both"];
  const fileTypeOptions = [
    { path: DOC, name: "Word Document" },
    { path: PDF, name: "PDF" },
    { path: RTF, name: "Formatted Text" },
    { path: TXT, name: "Plain Text" },
  ];

  const fileTypes = {
    "Formatted Text": "rtf",
    "Plain Text": "txt",
    PDF: "pdf",
    "Word Document": "doc",
  };

  const formatDate = (date) => {
    if (typeof date !== "object") return "";
    const posted = new Date(
      `${toMonthName(date.month)} ${date.date}, ${date.year} ${date.hour}:${
        date.minute
      }`
    );
    return `Updated  ${timeAgo.format(posted)}`;
  };

  const bookNameFormatter = (str) => {
    if (str.length > 15) return str; // str is introductio names
    return (
      str.charAt(0).toUpperCase() +
      str.substring(1, 4) +
      " " +
      str.substring(4, str.length)
    );
  };

  const processDownload = async () => {
    if (!downloadReady) return;
    if (
      selectedBook === "Lucan in English" ||
      selectedBook === "This Translation"
    ) {
      // SG 11/17/2022 23:00  handle google drive download
      const link = getLink(selectedBook, selectedfileType);
      var downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);
      downloadLink.href = link;
      downloadLink.download = `${selectedBook}.${fileTypes[selectedfileType]}`;
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } else {
      const fileParser = new FileParser(selectedBook);
      await fileParser.generateDocument(
        selectedBookContent.toLowerCase(),
        fileTypes[selectedfileType].toLowerCase()
      );
    }
  };

  useEffect(() => {
    fetchBookData().then((data) => {
      console.log(data);
      // data.sort((a, b) => a - b);
      setBookData(data);
    });
  }, []);

  useEffect(() => {
    if (
      selectedBook === "Lucan in English" ||
      selectedBook === "This Translation"
    ) {
      setSelectedBookContent("English");
    }
    if (selectedBook && selectedBookContent && selectedfileType) {
      setDownloadReady(true);
    }
  }, [selectedBook, selectedBookContent, selectedfileType]);

  useOutsideClickDetector(bookDropdownRef, () => setBookOpen(false), bookItem);
  useOutsideClickDetector(
    bookContentDropdownRef,
    () => setBookContentOpen(false),
    bookContentItem
  );
  useOutsideClickDetector(
    fileTypeDropdownRef,
    () => setfileTypeOpen(false),
    fileTypeItem
  );

  return (
    <div className="">
      <Navbar />
      {!bookData ? (
        <section className="min-h-[40rem] flex items-center justify-center">
          <Loading />
        </section>
      ) : (
        <section className="flex justify-center items-center flex-col w-screen">
          <h1 className="text-[1.8rem] text-gray-800 font-semibold my-10">
            Download
          </h1>
          <div>
            <div className="flex w-[20rem] md:w-[30rem] items-center">
              <span className="text-[1.3rem] font-semibold">Step 1</span>
              <span className="text-gray-700 font-medium ml-5">
                Select a Book
              </span>
            </div>
            <section className="relative">
              <div
                className={`w-full border-[2px] border-gray-300 mt-5 h-[3rem] flex items-center pl-4 rounded-lg bg-white cursor-pointer ${
                  bookOpen && "border-blue-600"
                }`}
                ref={bookDropdownRef}
                onClick={() => setBookOpen(!bookOpen)}
              >
                <p>
                  {selectedBook
                    ? bookNameFormatter(selectedBook)
                    : "Select an option"}
                </p>
                <Arrow
                  className={`ml-auto mr-5 transition duration-200 ease-in-out ${
                    bookOpen && "rotate-180"
                  }`}
                />
              </div>
              <section
                ref={bookItem}
                className={`bg-white rounded-md shadow-md border-[1px] border-gray-100 transition duration-200 ease-in-out absolute w-[20rem] md:w-[30rem] z-[10] ${
                  !bookOpen && "-translate-y-[16rem] scale-[0]"
                }`}
              >
                {Object.entries(bookData)
                  .sort(collator.compare)
                  .map(([key, value], index) => (
                    <div
                      className={`w-full h-fit px-4 py-3 hover:bg-gray-200 cursor-pointer flex ${
                        index === 0
                          ? "rounded-t-md"
                          : index === Object.entries(bookData).length - 1
                          ? "rounded-b-md"
                          : ""
                      }`}
                      key={key}
                      onClick={() => {
                        setSelectedBook(key);
                        setBookOpen(!bookOpen);
                      }}
                    >
                      <span className="font-semibold mr-5 text-gray-800">
                        {bookNameFormatter(key)}
                      </span>
                      <span className="text-gray-600 ml-5">{`${formatDate(
                        value
                      )}`}</span>
                    </div>
                  ))}
              </section>
            </section>
          </div>

          {/* Book Content */}
          <div>
            <div className="flex w-[20rem] md:w-[30rem] items-center mt-10">
              <span className="text-[1.3rem] font-semibold">Step 2</span>
              <span className="text-gray-700 font-medium ml-5">
                Select the Content
              </span>
            </div>
            <section className="relative">
              <div
                className={`w-full border-[2px] border-gray-300 mt-5 h-[3rem] flex items-center pl-4 rounded-lg bg-white cursor-pointer ${
                  bookContentOpen && "border-blue-600"
                } ${
                  selectedBook === "Lucan in English" ||
                  selectedBook === "This Translation"
                    ? "cursor-not-allowed"
                    : ""
                }`}
                ref={bookContentDropdownRef}
                onClick={() => {
                  if (
                    selectedBook === "Lucan in English" ||
                    selectedBook === "This Translation"
                  )
                    return;
                  setBookContentOpen(!bookContentOpen);
                }}
              >
                <p>
                  {selectedBookContent
                    ? selectedBookContent
                    : "Select an option"}
                </p>
                <Arrow
                  className={`ml-auto mr-5 transition duration-200 ease-in-out ${
                    bookContentOpen && "rotate-180"
                  }`}
                />
              </div>
              <section
                ref={bookContentItem}
                className={`bg-white rounded-md shadow-md border-[1px] border-gray-100 transition duration-200 ease-in-out absolute w-[20rem] md:w-[30rem] z-[10] ${
                  !bookContentOpen && "-translate-y-[3em] scale-[0]"
                }`}
              >
                {contentOptions.map((value, index) => (
                  <div
                    className={`w-full h-fit px-4 py-3 hover:bg-gray-200 cursor-pointer flex ${
                      index === 0
                        ? "rounded-t-md"
                        : index === contentOptions.length - 1
                        ? "rounded-b-md"
                        : ""
                    }`}
                    key={value}
                    onClick={() => {
                      setSelectedBookContent(value);
                      setBookContentOpen(!bookContentOpen);
                    }}
                  >
                    {" "}
                    <span className="font-semibold mr-5 text-gray-800">
                      {value}
                    </span>
                  </div>
                ))}
              </section>
            </section>
          </div>

          {/* File Type */}
          <div>
            <div className="flex w-[20rem] md:w-[30rem] items-center mt-10">
              <span className="text-[1.3rem] font-semibold">Step 3</span>
              <span className="text-gray-700 font-medium ml-5">
                Select File Type
              </span>
            </div>
            <section className="relative">
              <div
                className={`w-full border-[2px] border-gray-300 mt-5 h-[3rem] flex items-center pl-4 rounded-lg bg-white cursor-pointer ${
                  fileTypeOpen && "border-blue-600"
                }`}
                ref={fileTypeDropdownRef}
                onClick={() => setfileTypeOpen(!fileTypeOpen)}
              >
                <p>
                  {selectedfileType ? selectedfileType : "Select an option"}
                </p>
                <Arrow
                  className={`ml-auto mr-5 transition duration-200 ease-in-out ${
                    fileTypeOpen && "rotate-180"
                  }`}
                />
              </div>
              <section
                ref={fileTypeItem}
                className={`bg-white rounded-md shadow-md border-[1px] border-gray-100 transition duration-200 ease-in-out absolute w-[20rem] md:w-[30rem] z-[10] ${
                  !fileTypeOpen && "-translate-y-[6em] scale-[0]"
                }`}
              >
                {fileTypeOptions.map((value, index) => (
                  <div
                    className={`w-full h-fit px-4 py-3 hover:bg-gray-200 cursor-pointer flex items-center ${
                      index === 0
                        ? "rounded-t-md"
                        : index === fileTypeOptions.length - 1
                        ? "rounded-b-md"
                        : ""
                    }`}
                    key={value.name}
                    onClick={() => {
                      setSelectedfileType(value.name);
                      setfileTypeOpen(!fileTypeOpen);
                    }}
                  >
                    <img
                      alt={value.name}
                      src={value.path}
                      className="w-[2rem] mr-5"
                    />{" "}
                    <span className="font-semibold mr-5 text-gray-800">
                      {value.name}
                    </span>
                  </div>
                ))}
              </section>
            </section>
          </div>

          <button
            className={`mt-10 h-[3rem] w-[20rem] md:w-[30rem] rounded-lg font-semibold text-white ${
              downloadReady
                ? "bg-blue-600 hover:bg-blue-700"
                : "cursor-not-allowed bg-blue-300"
            }`}
            onClick={processDownload}
          >
            Download Document
          </button>

          <div></div>
        </section>
      )}
      {/* <div className="d-type-container">
        <div className="download-type">
          <span>Name</span>
          <span>Content</span>
          <span>File Type</span>
          <span>Download Link</span>
        </div>
      </div>
      <hr className="download-hr" />

      {cardInfo.map((item) => {
        return (
          <ItemCard
            name={item.name}
            id={cardInfo.indexOf(item)}
            key={cardInfo.indexOf(item)}
          />
        );
      })} */}
      <Footer />
    </div>
  );
};

export default Download;
