import { onAuthStateChanged } from "firebase/auth";
import { collection, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import BookTemplete from "./components/bookTemplete";
import Download from "./components/download/Download";
import Home from "./components/home/Home";
import { Introduction } from "./components/introduction/Introduction";
import Introduction2 from "./components/introduction/Introduction2";
import Login from "./components/login/Login";
import Working from "./components/other/Working";
import { auth, db } from "./firebase/base";

const App = () => {
  // TODO: SG 11/13/2022 13:00  fetch reviews for introduction page
  const [reviews, setReview] = useState([]); // eslint-disable-line
  const [ip, setIp] = useState("");
  const [user, setUser] = useState(null);

  useEffect(() => {
    // set user
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  }, []);

  useEffect(() => {
    // retrive data from dataabse and assign it to reviews
    var dataArray = [];
    const getReviews = async () => {
      onSnapshot(collection(db, "bookReviews"), (data) => {
        // console.log('Data fetched');
        data.docs.forEach((doc) => {
          doc.data().data.forEach((datas) => {
            dataArray.push({ ...datas, id: datas.id });
          });
        });
        setReview(dataArray);
        dataArray = [];
      });
    };
    getReviews();
  }, []);

  useEffect(() => {
    // get ip address of client
    fetch("https://api.ipify.org?format=json")
      .then((res) => res.json())
      .then((data) => setIp(data.ip))
      .catch((err) => console.log(err));
  }, []);

  const books = [
    {
      path: "book1",
      displayBookName: "Book I",
    },
    {
      path: "book2",
      displayBookName: "Book II",
    },
    {
      path: "book3",
      displayBookName: "Book III",
    },
    {
      path: "book4",
      displayBookName: "Book IV",
    },
    {
      path: "book5",
      displayBookName: "Book V",
    },
    {
      path: "book6",
      displayBookName: "Book VI",
    },
    {
      path: "book7",
      displayBookName: "Book VII",
    },
    {
      path: "book8",
      displayBookName: "Book VIII",
    },
    {
      path: "book9",
      displayBookName: "Book IX",
    },
    {
      path: "book10",
      displayBookName: "Book X",
    },
  ];

  return (
    <>
      <ReactNotifications />
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route
            exact
            path="/lucan-in-english"
            element={<Introduction user={user} reviews={reviews} ip={ip} />}
          />
          <Route
            exact
            path="/this-translation"
            element={<Introduction2 user={user} reviews={reviews} ip={ip} />}
          />
          <Route exact path="/admin" element={<Login />} />

          {/* Generating routes for books */}
          {books.map((book, index) => (
            <Route
              key={index}
              exact
              path={book.path}
              element={
                <BookTemplete
                  user={user}
                  ip={ip}
                  book={book.path}
                  displayBookName={book.displayBookName}
                />
              }
            />
          ))}

          <Route exact path="/download" element={<Download />} />
          <Route exact path="/working" element={<Working />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
