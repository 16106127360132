import React from "react";
import { useNavigate } from "react-router-dom";
import "./footer.css";
import Contact from "./Contact";
import { useState, useEffect } from "react";
import { Store } from "react-notifications-component";
import myBackground from "../../assets/longerBackground.svg";
function Footer() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [successEmail, setSuccessMessage] = useState(false);

  useEffect(() => {
    if (successEmail) {
      Store.addNotification({
        title: "Message Sent!",
        message: "Your message has been successfully sent.",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      setSuccessMessage(false);
    }
  }, [successEmail]);

  return (
    <div
      className="footer-banner-container"
      style={{ backgroundImage: `url(${myBackground})` }}
    >
      <Contact
        open={open}
        close={() => setOpen(false)}
        setSuccess={setSuccessMessage}
      />
      <div
        className="footer-container"
        style={{ backgroundImage: `url(${myBackground})` }}
      >
        <div className="footer-title-info">
          <h1>Lucan Translation</h1>
          <p>
            A new online English translation <br /> with interactive reader
            participation
          </p>
        </div>

        <div className="footer-books">
          <h2>Books</h2>
          <div className="footer-books-container">
            <a href="/book1" className="text-white font-semibold">
              I
            </a>
            <a href="/book2" className="text-white font-semibold">
              II
            </a>
            <a href="/book3" className="text-white font-semibold">
              III
            </a>
            <a href="/book4" className="text-white font-semibold">
              IV
            </a>
            <a href="/book5" className="text-white font-semibold">
              V
            </a>
            <a href="/book6" className="text-white font-semibold">
              VI
            </a>
            <a href="/book7" className="text-white font-semibold">
              VII
            </a>
            <a href="/book8" className="text-white font-semibold">
              VIII
            </a>
            <a href="/book9" className="text-white font-semibold">
              IX
            </a>
            <a href="/book10" className="text-white font-semibold">
              X
            </a>
          </div>
        </div>

        <div className="footer-intro">
          <h2 onClick={() => navigate("/")}>Introduction</h2>
          <span id="f-i-f" onClick={() => navigate("/lucan-in-english")}>
            Lucan in English
          </span>
          <span id="f-i-s" onClick={() => navigate("/this-translation")}>
            This Translation
          </span>
        </div>

        <div className="footer-download">
          <h2 onClick={() => navigate("/download")}>Download</h2>
          <button className="footer-contact-btn" onClick={() => setOpen(true)}>
            Contact Author
          </button>
        </div>
      </div>
      <div className="w-screen flex justify-center items-center">
        <div className=" flex justify-between items-start w-full px-20 h-[3em]">
          <p className="pb-2">
            @2022 lucantranslation.com All Rights Reserverd
          </p>
          <p id="footer-me">
            Website built by Sam Goto. Business inquiries:{" "}
            <a href="mailto:osgo1115@icloud.com">osgo1115@icloud.com</a>{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

//@2022 lucantranslation.com All Rights Reserverd

export default Footer;
