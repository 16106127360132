import React, { useEffect } from "react";
import "./Login.css";
import { useState } from "react";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "../../firebase/base";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
// import Navbar from '../navbar/navbar';

function Login() {
  var navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [user, setUser] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/book1"); // login successful yay
    } catch (err) {
      // setErrMessage('Login Failed. Please Check your username or Password.');
      setErrMessage(err.message);
    }
  };

  const logout = () => {
    signOut(auth);
    navigate("/");
  };

  if (!user) {
    return (
      <div>
        <form className="input-form" onSubmit={handleLogin}>
          <div className="input-card">
            <span className="input-title">Admin Login</span>
            <p className="login-errr-message" style={{ color: "red" }}>
              {errMessage}
            </p>
            <div className="input-align">
              <label htmlFor="username">
                <svg
                  className="input-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M20.822 18.096c-3.439-.794-6.64-1.49-5.09-4.418 4.72-8.912 1.251-13.678-3.732-13.678-5.082 0-8.464 4.949-3.732 13.678 1.597 2.945-1.725 3.641-5.09 4.418-3.073.71-3.188 2.236-3.178 4.904l.004 1h23.99l.004-.969c.012-2.688-.092-4.222-3.176-4.935z" />
                </svg>
              </label>
              <input
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
                placeholder="username"
                id="username"
                name="username"
                className="input-field-login"
              />
            </div>
            <div className="input-align">
              <label htmlFor="password">
                <svg
                  className="input-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M12.451 17.337l-2.451 2.663h-2v2h-2v2h-6v-1.293l7.06-7.06c-.214-.26-.413-.533-.599-.815l-6.461 6.461v-2.293l6.865-6.949c1.08 2.424 3.095 4.336 5.586 5.286zm11.549-9.337c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zm-3-3c0-1.104-.896-2-2-2s-2 .896-2 2 .896 2 2 2 2-.896 2-2z" />
                </svg>
              </label>
              <input
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                required
                placeholder="password"
                id="password"
                name="password"
                type="password"
                className="input-field-login"
              />
            </div>
            <p className="login-des">
              To become an admin, <br />
              please contact the site administrator
            </p>
            <button type="submit" className="login-btn">
              Login
            </button>
          </div>
        </form>
      </div>
    );
  } else if (user) {
    return (
      <div>
        <form className="input-form" onSubmit={handleLogin}>
          <div className="input-card">
            <span className="input-title">Logged in as Admin</span>
            <button type="button" onClick={logout} className="login-btn">
              Log out
            </button>
            <button
              type="button"
              onClick={() => navigate("/book1")}
              className="login-btn"
            >
              Go to Dashboard
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default Login;
