import React from "react";
import "./Introduction.css";
import Navbar from "../navbar/navbar";
import Part1 from "./part1";
import Footer from "../footer/Footer";

function Introduction({ user, reviews, ip, archive }) {
  return (
    <div>
      <Navbar></Navbar>
      {user ? <p className="login-status">Logged in as Admin</p> : <p></p>}
      <Part1 user={user} reviews={reviews} ip={ip} archive={archive}></Part1>
      <Footer></Footer>
    </div>
  );
}

export { Introduction };
