const getLink = (name, type) => {
  let link = "https://docs.google.com/uc?export=download&id="; //direct download link

  if (name === "Lucan in English") {
    if (type === "Formatted Text") {
      link += "1qYifooR4MpEJmM-JK33AZLrsAI9NUZ6a";
    } else if (type === "Plain Text") {
      link += "16fFJbB0uNbDrFldsD3mFSe9ttPTpotEC";
    } else if (type === "Word Document") {
      link += "1J2dYZbKk6u0VQM1d-UGakAi9lwWmgqKh";
    } else if (type === "PDF") {
      link += "12YGTQUGhPVmM7MNjCN0LhOv7Dzht7HFR";
    }
  } else if (name === "This Translation") {
    if (type === "Formatted Text") {
      link += "1v0MQAenes0GziEy_VnjKr72_KJEmadYH";
    } else if (type === "Plain Text") {
      link += "1hIvBFB4EniLVnvDCrXX09f5FDYun-unu";
    } else if (type === "Word Document") {
      link += "1CVmVnAftuBlYKYe2J3QaXEYlwRRzy5FX";
    } else if (type === "PDF") {
      link += "1Nl8-I7vMnFizDJl58zoQ_PQugkZO45aJ";
    }
  }
  return link;
};

export default getLink;
