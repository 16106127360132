import React from "react";
import "./part1.css";
import { useState, useEffect } from "react";
import CommentSection from "./CommentSection";

function Part1({ reviews, archive, ip, user }) {
  const [page, setPage] = useState("1");

  const commentDataArray = [];
  reviews.forEach((review) => {
    if (review.id === "book-intro1") {
      commentDataArray.push(review);
    }
  });

  const scroll = (id) => {
    document.getElementById("citation-i-" + id).scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]); // when user goes to another page, it takes them top the top of the page

  useEffect(() => {
    if (page === "1") {
      var content;
      for (var i = 1; i <= 32; i++) {
        if (i === 11 || i === 29 || i === 31) {
          // skip
        } else if (i === 10 || i === 28 || i === 30) {
          content = "Click for more details..........";
          content +=
            "\n\n" + document.getElementById(`citation-i-${i}`).innerText;
          document
            .getElementById(`intro-hover-dis${i}`)
            .setAttribute("data-tip", content);
        } else if (i === 15) {
        } else {
          content = document.getElementById(`citation-i-${i}`).innerText;
          document
            .getElementById(`intro-hover-dis${i}`)
            .setAttribute("data-tip", content);
        }
      }
    } else if (page === "2") {
      for (i = 33; i <= 47; i++) {
        if (
          i === 38 ||
          i === 39 ||
          i === 41 ||
          i === 42 ||
          i === 43 ||
          i === 44 ||
          i === 45 ||
          i === 46
        ) {
        } else if (i === 33) {
        } else if (i === 37 || i === 40) {
          content = "Click for more details...........";
          content +=
            "\n\n" + document.getElementById(`citation-i-${i}`).innerText;
          document
            .getElementById(`intro-hover-dis${i}`)
            .setAttribute("data-tip", content);
        } else {
          content = document.getElementById(`citation-i-${i}`).innerText;
          document
            .getElementById(`intro-hover-dis${i}`)
            .setAttribute("data-tip", content);
        }
      }
    }
  }, [page]);
  if (page === "1") {
    return (
      <>
        <h1 className="i-title-intro">Lucan in English</h1>
        <br />
        <div className="intro-container">
          <div className="intro-page">
            <p className="introduction-p">
              The history of translating Lucan’s civil war epic into English is
              a tale of alternating neglect and enthusiasm. Lucan was popular in
              his own time, widely read in Europe throughout the middle ages and
              renaissance, translated continuously into the romance languages,
              and by Dante ranked alongside Virgil.
              <sup className="sup-link">
                <span
                  id="intro-hover-dis1"
                  onClick={(e) => scroll(1)}
                  data-tip=""
                >
                  1
                </span>
              </sup>{" "}
              In England he was ignored for fifteen hundred years.
            </p>
            <p className="introduction-p">
              And then, starting in 1595 with Samuel Daniel, came a volley of
              imitations and translations interrupted by England’s civil wars. A
              long silence followed, but in the 20<sup>th</sup> and early 21
              <sup>st</sup> centuries another wave of translations brought the
              Bellum Civile back. What was it about Lucan that stimulated his
              belated “discovery” in Shakespeare’s England? What silenced him in
              the mid-17<sup>th</sup> century? And why was he resurrected in the
              early 18<sup>th</sup> and again today? Why does Lucan seem to be -
              at least in English and with apologies to Ben Jonson - not for all
              time but for an age. And with what metrical and tonal diversity
              has Lucan’s poetry been conveyed in English over the course of
              four hundred years?
            </p>
            <p className="introduction-p">
              This introduction will consider the eleven English verse
              translations of the Bellum Civile published between 1600 and the
              present, selecting for comparative attention Lucan’s
              sixty-six-line proem. I will consider some of the issues English
              translators face in approaching Lucan’s poetry, then describe the
              historical context and poetics of his early translations (shedding
              light perhaps on their intermittency), and finally discuss the
              poetics of recent translators.
            </p>
            <p className="introduction-p">
              Of course, its proem cannot adequately represent all the Bellum
              Civile. Lucan had many poetic skills, not limited to aphorism,
              drama, fighting, description (astral and local), grotesquerie, and
              outrage. Passages purple and famous abound in his epic. However,
              both to poet and translator an epic’s proem invites the
              establishment of a tone and intent, a poetic stance and stamp. I
              hope that this sixty-six-line passage is long enough to suggest at
              least the approach taken by Lucan's individual translators, short
              enough to keep a discussion of eleven different works from
              becoming tiresome, and sufficiently representative of Lucan’s own
              style to make clear how each translator altered it to accommodate
              the new language.{" "}
            </p>
            <p className="introduction-p">
              It is necessary to the plan of this overview but rather unfair to
              the authors considered that in so short an extract (less than one
              percent of the poem) I represent their translations of over eight
              thousand lines. Moments of particular brilliance cannot be
              highlighted, nor any cumulative effects of a translation pointed.
              This slight is only the worse to translators who have undertaken a
              poet so mercurial as Lucan. My purpose here is mainly to capture
              and display together, only secondarily to critique, the diversity
              of styles - and particularly of meters - that arose from Lucan’s
              poetry. That and to speculate about the on-again, off-again timing
              of these translations over four centuries.
            </p>
            <hr></hr>
            <h1 className="g1">General considerations</h1>
            <br />
            <br />
            <div className="i-center-para i-top">
              <p className="introduction-p">
                <em>
                  neque enim possunt carmina, quamvis optime composita, ex alia
                  in aliam linguam
                  <br />
                  ad verbum sine detrimento sui decoris ac dignitatis
                  transferri.
                </em>
              </p>
              <p className="introduction-p">
                <em>
                  Nor can poems, however excellent in their composition, be
                  carried across from one language to
                  <br /> another word for word without detriment to their beauty
                  and dignity.
                </em>
              </p>
            </div>
            <p className="introduction-p i-r-p">
              Bede, <em>Historia Ecclesiastica Gentorum Anglorum</em>
            </p>
            <p className="introduction-p" id="i-upper">
              Translation, I think, whether free or “ad verbum,” always falls
              short. How, for example, is even Lucan’s (usually applied) title
              to be translated? The absence of articles in Latin forces{" "}
              <em>“De Bello Civili”</em> to hover indecisively between the
              historical (“On the Civil War”) and the didactic (“On Civil War”).
              <sup className="sup-link">
                <span id="intro-hover-dis2" onClick={(e) => scroll(2)}>
                  2
                </span>
              </sup>{" "}
              In a sense the translator’s job is choosing a way to fall short.
            </p>
            <h2 className="introduction-title t-bold">Meter</h2>
            <p className="introduction-p">
              The first dilemma is whether to use a native meter or one that
              sonically reproduces, in some way, the original. Must Lucan’s
              sound take precedence, or should the translator be content to make
              it seem that <em>De Bello Civili</em> has a comfortable place in
              the metrical traditions of its new language?
            </p>
            <p className="introduction-p">
              There is a worthy, ancient, and continuing history of imitating
              with stress Latin’s quantitative downbeats. This recurrent attempt
              has been applied mainly to Virgil, but the dactylic impulse is
              also present in PF Widdows’ 1988 translation of Lucan and to some
              extent in the free verse translations of Susanna Braund (1992) and
              Jane Joyce (1993). It is worth glancing briefly at the history of
              dactylic translations to get a sense of their possibilities.
            </p>
            <p className="introduction-p">
              Most of these were of Virgil’s Aeneid, giving ready means to
              compare them. Richard Stanyhurst in 1583 was first:
            </p>
            <div className="i-center-para">
              <p className="introduction-p i-special">
                Now mahod and garbroyls I chaunt, and martial horror.
                <br />
                I blaze thee captayne first from Troy cittye repairing,
                <br />
                Lyke wandring pilgrim too famosed Italie trudging, <br />
                And coast of Lauyn: soust wyth tempestuus hurlwynd, <br />
                On land and sayling, by Gods predestinat order.
                <sup className="sup-link">
                  <span id="intro-hover-dis3" onClick={(e) => scroll(3)}>
                    3
                  </span>
                </sup>
              </p>
            </div>
            <p className="introduction-p">
              That <em>Aeneid</em> made Stanyhurst a literary pariah. Thomas
              Nash, at the time, wrote “Mr. Stanyhurst, though otherwise
              learned, trod a foul, lumbering, boisterous, wallowing measure in
              his translation of Virgil.” A full century later Thomas Warton
              piled on: “...in his choice of measure he is more unfortunate than
              his predecessors and in other respects succeeded worse.” And still
              another century later from a dyspeptic Southey: “As Chaucer has
              been called the well of English undefiled, so might Stanyhurst be
              denominated the common sewer of the language.” After Stanyhurst,
              nobody tried it again for a long time.
            </p>
            <p className="introduction-p">
              Not until 1865, when a not more precisely identified “W. Grist,”
              headmaster of a public school in South London, timidly ventured
              his Aeneid in hexameter. “With a view,” he wrote (mindful perhaps
              of Stanyhurst’s fate and dusting off a shop-worn recusatio), “to
              assisting my own pupils in the work of translating Virgil." <br />
              <br /> Grist began:
            </p>
            <div className="i-center-para">
              <p className="introduction-p i-special">
                Wars and their hero I sing, who, first from Ilion departing
                <br />
                Exiled by fate sought Italy’s shores and came to Lavinum.
                <br />
                Great were his woes, endured long years on land and ocean,
                <br />
                Sent by the gods all-powerful, and Juno’s anger unbending.
                <br />
                Many in war his perils were; til, building a city,
                <br />
                Brought he into the Latian and his Pennates; whence rose the
                <br />
                Latin race, and the Alban kings, and Rome’s lofty ramparts.
                <sup className="sup-link">
                  <span id="intro-hover-dis4" onClick={(e) => scroll(4)}>
                    4
                  </span>
                </sup>
              </p>
            </div>
            <p className="introduction-p">
              A less tentative Oliver Crane in 1888 published what he called
              “Virgil’s Aeneid translated line by line into English dactylic
              hexameter.” <br></br>This is his opening:
            </p>
            <div className="i-center-para">
              <p className="introduction-p i-special">
                Arms and the hero I sing, who of old from the borders of Troja
                <br />
                Came to Italia banished by fate to Lavinia’s destined
                <br />
                Seacoasts: much was he tossed on the lands and the deep by
                enlisted
                <br />
                Might of supernals, through ruthless Juno’s remembered
                resentment:
                <br />
                Much too, he suffered in warfare while he was founding a city
                <br />
                And into Latium bearing his gods whence issued the Latin
                <br />
                Race and Alban fathers and walls of imperial Roma.
                <sup className="sup-link">
                  <span id="intro-hover-dis5" onClick={(e) => scroll(5)}>
                    5
                  </span>
                </sup>
              </p>
            </div>
            <p className="introduction-p">
              The next and, to my knowledge the latest, version of the Aeneid in
              dactyl/spondee hexameter is by Frederick Ahl, a well-known
              American scholar, in 2007:
            </p>
            <div className="i-center-para">
              <p className="introduction-p i-special">
                Arms and the man I sing of Troy, who first from its seashores,
                <br />
                Ital-bound, fate’s refugee arrived at Lavinia’s
                <br />
                Coastlands. How he battered about over land, over high deep
                <br />
                Seas by the powers above! Savage Juno’s anger remembered
                <br />
                Him, and he suffered profoundly in war to establish a city,
                <br />
                Settle his gods into Latium, making this land of the latins
                <br />
                Future home to Elders of Alba and Rome’s mighty ramparts.
                <sup className="sup-link">
                  <span id="intro-hover-dis6" onClick={(e) => scroll(6)}>
                    6
                  </span>
                </sup>
              </p>
            </div>
            <p className="introduction-p">
              None of the above translations sound like English to my ear, and
              the oddity of them takes focus off the poem. Certainly, Lucan’s 17
              <sup>th</sup> century translators all opted for English meters,
              either those of Chaucer or the blank verse then recently
              introduced. By the time of Rowe in 1719 the authority of Chaucer
              and then of Dryden had made English verse almost synonymous with
              iambic pentameter.{" "}
            </p>
            <h2 className="introduction-title t-bold">Rhyme</h2>
            <p className="introduction-p">
              Rhyme also, by 1719, was at its high-water mark in England. There
              was then no (serious) English verse without rhymed couplets. But a
              century earlier it may have been thought odd that two university
              men like Arthur Gorges and Thomas May (but not the third
              university man, Christopher Marlowe) chose rhyming couplets to
              translate Latin hexameter. The cultural elite in Elizabethan
              England had an aversion to rhyme. In 1570 Roger Ascham called it:
            </p>
            <div className="i-center-para">
              <p className="introduction-p i-special">
                Our rude beggarly ryming, brought first into Italie by Gothes
                and Hunnes, <br />
                whan all good verses and all good learning to, were destroyd by
                them: and <br />
                after caryed into France and Germanie: and at last recyved into
                England by <br />
                men of excellent wit in deede, but of small learning and lesse
                judgment in <br />
                that behalf.
                <sup className="sup-link">
                  <span id="intro-hover-dis7" onClick={(e) => scroll(7)}>
                    7
                  </span>
                </sup>
              </p>
            </div>
            <p className="introduction-p">
              That English poets mostly ignored Ascham may be judged not only
              from the practice of Lucan’s early translators, but also by
              Milton’s need to defend his choice of meter in{" "}
              <em>Paradise Lost</em>, a century later:
            </p>
            <div className="i-center-para">
              <p className="introduction-p i-special">
                The Measure is English Heroic Verse without Rime, as that of
                Homer in <br />
                Greek, and Virgil in Latin; Rhime being no necessary Adjunct or
                true <br />
                Ornament of Poem or good Verse, in longer Works especially, but
                the <br />
                Invention of a barbarous Age, to set off wretched matter and
                lame Meeter.
                <sup className="sup-link">
                  <span id="intro-hover-dis8" onClick={(e) => scroll(8)}>
                    8
                  </span>
                </sup>
              </p>
            </div>
            <p className="introduction-p">
              Apart from any gothic barbarity it is liable to introduce, rhyme
              also poses a problem in conveying Latin’s concision on the one
              hand and its complex, often drawn out sentence structure on the
              other. Rhyme can be expected to lengthen or else to simplify the
              Latin original by often requiring filler words whose primary
              function, no matter how well disguised, is to set up the rhyme.
              This is true especially in English, because our word endings are
              neither highly conventional nor gendered so as to provide the
              language with plentiful rhymes.
              <sup className="sup-link">
                <span id="intro-hover-dis9" onClick={(e) => scroll(9)}>
                  9
                </span>
              </sup>{" "}
              Harder to find, English rhymes must more often be a little remote
              in salience and require additional words that excuse the slightly
              extraneous sense. Worse, the remarkable variety in the length of
              Latin sentences and clauses and the intricacy of their
              construction must be parceled into predetermined lengths and
              frequently brought up short by the finality that rhyme at strict
              intervals confers.
            </p>
            <p className="introduction-p">
              None of the six most recent verse translations of Lucan is rhymed,
              either because written in the commonest prosody of the last
              century, free verse, or in an attempt to reproduce the original,
              either sonically in dactyls or conceptually in “English Heroic
              Verse.”
            </p>
            <h2 className="introduction-title t-bold">
              Interpretation and pace
            </h2>
            <p className="introduction-p">
              A major interpretive decision in translating the proem of{" "}
              <em>De Bello Civili</em> is how openly, if at all, to emphasize
              irony in Lucan’s <em>laus Neronis</em> and his invocation of Nero
              in preference to muse or god (for here is the beginning of Lucan’s
              extraordinary exclusion of the Roman pantheon from an epic poem).
              Lucan’s abrupt about face, from excoriating civil war to
              justifying it, is only the first of many such sudden changes in
              viewpoint, almost school exercises in rhetorical <em>colores</em>.
              My own sense is that to Lucan – at least in this poem – both gods
              and emperor are useless at best, enemies at worst. The first
              ignored civil war’s outrage, the second became its heir. I think
              Lucan’s challenge (and his poetic joy) was to tread exactly on the
              line – that calculation delicate – between praise of the emperor,
              praise that was in any case expected to be inflated and formulaic,
              and angry, seditious irony. Lucan could go a little farther than
              most, one supposes, because he must have arrived to{" "}
              <em>De Bello Civili</em>, at least in its early days, with some
              store of goodwill for his previous, prize-winning praise of Nero.
              The pace of Lucan’s first 32 lines can be interpreted as rapid or
              distracted, the language contemptuous or rhetorical. The decisions
              are personal to the translator, but one uncontroversial way to
              reproduce his pace is to stay at Lucan’s length.
            </p>
            <hr></hr>
            <h1 className="g1">
              Early translators of Lucan: Historical Context
            </h1>
            <br />
            <br />
            <br />
            <p className="introduction-p">
              Not translators of <em>De Bello Civili</em>, but almost slavish
              imitators of it, both Samuel Daniel (in editions 1595-1609) and
              Michael Drayton (1603) produced epics decrying, respectively,
              English civil wars of the 15<sup>th</sup> and 14<sup>th</sup>{" "}
              centuries.
              <sup className="sup-link">
                <span id="intro-hover-dis10" onClick={(e) => scroll(10)}>
                  10, 11
                </span>
              </sup>{" "}
              Playwright Marlowe was the first English translator of Lucan.{" "}
              <sup className="sup-link">
                <span id="intro-hover-dis12" onClick={(e) => scroll(12)}>
                  12
                </span>
              </sup>{" "}
              The poet Barnabe Googe almost beat him to it, but gave up his
              projected translation in 1560 citing objections from Calliope.{" "}
              <sup className="sup-link">
                <span id="intro-hover-dis13" onClick={(e) => scroll(13)}>
                  13
                </span>
              </sup>{" "}
              George Turberville, Googe’s friend, might then have been first,
              except that, according to his own account, Melpomene took him in
              hand and administered the same sort of chiding that Ovid got from
              Cupid.{" "}
              <sup className="sup-link">
                <span id="intro-hover-dis14" onClick={(e) => scroll(14)}>
                  14
                </span>
              </sup>{" "}
              The tragic muse told Turberville that he simply wasn’t poet enough
              to translate Lucan. In England, at least since the 1300’s, tragedy
              and epic were not viewed as generically very distant, so
              Melpomene, the tragic muse, spoke with a reasonable degree of
              authority.
              {/* footnote 15 with quote */}
              <sup className="sup-link special-fn">
                <span id="intro-hover-dis15" onClick={(e) => scroll(15)}>
                  15
                </span>
                <p id="intro-hover-special">
                  <p id="fn-b-center">
                    15 See for example Chaucer’s envoi to his epic Troilus and
                    Criseyde: <br />
                    <br />
                    Go, litel bok, go, litel my tragedye,
                    <br />
                    ther God thi makere yet, er that he dye
                    <br />
                    so sende might to in som comedye!
                    <br />
                  </p>
                </p>
              </sup>{" "}
              She said that attempting Lucan would "spoyle Lute, & stroy
              strings, / in straining them too farre.
              <sup className="sup-link">
                <span id="intro-hover-dis16" onClick={(e) => scroll(16)}>
                  16
                </span>
              </sup>{" "}
              It is also possible that both Calliope and Melpomene had personal
              concerns about the politics of translating anti-monarchist Lucan.
            </p>
            <p className="introduction-p">
              In any event, Turberville gave it up in 1574, clearing the way for
              devil-may-care Kit Marlowe. He translated only the first book of
              the Bellum Civile, and it is uncertain whether he did so at
              university or just before his early, violent death in 1594.
              Marlowe’s Lucan was not published until 1600. Possibly he had
              planned a treatment of the whole poem; we do not know. Two more
              translations, these complete, followed in 1614 (Gorges)
              <sup className="sup-link">
                <span id="intro-hover-dis17" onClick={(e) => scroll(17)}>
                  17
                </span>
              </sup>{" "}
              and 1627-1640 (May).{" "}
              <sup className="sup-link">
                <span id="intro-hover-dis18" onClick={(e) => scroll(18)}>
                  18
                </span>
              </sup>{" "}
              And then, at the dawn of civil war in England, this fervor to
              translate Lucan quite suddenly cooled. When, seventy years later,
              interest in Roman civil war epic returned, Statius’ star was in
              the ascendant with an outpouring of translations from his{" "}
              <em>Thebais</em>.
            </p>
            <p className="introduction-p">
              For fifty years – from the bishops’ wars beginning in 1640 through
              the mopping up after a (not so) “bloodless revolution” in 1689 –
              the British Isles were in a perpetual state of intermittent civil
              war.{" "}
              <sup className="sup-link">
                <span id="intro-hover-dis19" onClick={(e) => scroll(19)}>
                  19
                </span>
              </sup>{" "}
              During those same years, England’s leading poets were all
              Latinists – men of different politico-religious stripes who not
              only read but wrote Latin poetry: eminent John Dryden, translator
              of the <em>Aeneid</em>, priestly George Herbert, puritan John
              Milton, adroit Andrew Marvel, cavaliers Richard Lovelace, Robert
              Herrick and John Suckling, “metaphysicals” Richard Crashaw,
              Abraham Cowley and Henry Vaughn.
            </p>
            <p className="introduction-p">
              One might expect, in such times and among authors such as these,
              that Lucan would attract comment and, especially, translators.
              Yet, Lucan’s name disappeared. Early interest in translating{" "}
              <em>De Bello Civili</em> is found only just before England’s civil
              wars: Googe, Turberville, Marlowe, Gorges, May, Thomas Farnaby,
              who published the first scholarly Latin edition of{" "}
              <em>De Bello Civili</em> in 1618.{" "}
              <sup className="sup-link">
                <span id="intro-hover-dis20" onClick={(e) => scroll(20)}>
                  20
                </span>
              </sup>{" "}
              And then again just afterwards with Nicholas Rowe’s 1719
              translation.{" "}
              <sup className="sup-link">
                <span id="intro-hover-dis21" onClick={(e) => scroll(21)}>
                  21
                </span>
              </sup>{" "}
              To account for this pause in and resumption of interest, it is
              necessary to consider both poets of the Roman civil wars, Lucan
              and Statius. The 18<sup>th</sup> century revival in translating
              Lucan consists of a single work, while Statius finds a raft of new
              translators. Nearly four hundred years after Chaucer’s borrowings
              from "Stace", we suddenly see English translations of{" "}
              <em>Thebais</em> – or more accurately <em>from</em> it – by
              Christopher Pitt 1727, Walter Harte 1727 and 1731, Henry Travers
              1731, Jabez Hughes 1737, Thomas Gray 1775, and others.
            </p>
            <p className="introduction-p">
              A faint intimation of the Statius revival can be found during
              England’s civil war. Thomas Stephens, an obscure schoolmaster,
              published his only work in 1648, an English version, “for help”
              (of course) “to my scholars,” of five books from <em>Thebais</em>.{" "}
              <sup className="sup-link">
                <span id="intro-hover-dis22" onClick={(e) => scroll(22)}>
                  22
                </span>
              </sup>{" "}
              Stephens’ introduction hinted that he might complete the
              translation should there be any general interest. Apparently,
              there wasn’t. In addition to Stephens, and attached to a
              translation of the ever-popular Juvenal X, Thomas Shadwell (1687)
              published his rendering of Cato’s speech to Libienus in Book IX of{" "}
              <em>De Bello Civili</em>.
              <sup className="sup-link">
                <span id="intro-hover-dis23" onClick={(e) => scroll(23)}>
                  23
                </span>
              </sup>{" "}
              This translation, including a sharp critique of Shadwell's Latin,
              was revised by a Thomas Higden and then by another, anonymous
              author. And that was all for translating Roman civil war poets
              between 1640 and 1719.
            </p>
            <p className="introduction-p">
              Nor translation only, but discussion of Lucan and Statius was
              silent as well, awaiting renewal by Thomas Rymer in his
              controversy- provoking comparison of Statius with Dryden.
              <sup className="sup-link">
                <span id="intro-hover-dis24" onClick={(e) => scroll(24)}>
                  24
                </span>
              </sup>{" "}
              Not that there was any lack of interest in epic poetry during
              these years. Milton composed two long epic poems. Dryden
              translated the <em>Aeneid</em>. Epic in the Latin language was
              also represented: the contemporary and Jacobite <em>Grameidos</em>
              , published just after the glorious revolution, in 1691, extoled
              holy war.{" "}
              <sup className="sup-link">
                <span id="intro-hover-dis25" onClick={(e) => scroll(25)}>
                  25
                </span>
              </sup>{" "}
            </p>
            <p className="introduction-p">
              It is tempting to conclude from a silence so unexpected and sudden
              that the moral attitude offered in both <em>De Bello Civili</em>{" "}
              and <em>Thebais</em> – condemnation of civil war as <em>nefas</em>{" "}
              –found but cold welcome in mid and late 17<sup>th</sup> century
              England, Scotland, and Ireland. Reluctance to engage with these
              works could be seen as a measure of the ambient disposition
              towards war. One might otherwise suppose Lucan’s republicanism to
              have found many eager translators in the mid-17<sup>th</sup>{" "}
              century British Isles. More even than among his ardent adherents
              of the late 16<sup>th</sup> and early 17<sup>th</sup> centuries.{" "}
              <sup className="sup-link">
                <span id="intro-hover-dis26" onClick={(e) => scroll(26)}>
                  26
                </span>
              </sup>{" "}
              And one might otherwise have expected the renewed interest in
              Statius to have arrived more robustly than in the partial work of
              an obscure schoolmaster.
            </p>
            <p className="introduction-p">
              In England, as in Rome, limited monarchy prevailed. In both,
              decades passed after the end of civil unrest before full-throated
              condemnation of it became acceptable art. The 18<sup>th</sup>{" "}
              century brought a renewal of interest in the Roman civil war
              poets. These new translations were characterized by a shift in
              focus, as mentioned, from Lucan to the stylistically more
              Augustan, less “uncontrolled” Statius (perhaps beginning with
              Stephens during the civil war period). Other characteristics were
              a tendency to expand and further smooth the original, a ubiquity
              of heroic couplets as the only conceivable prosody, and
              translation of selected books or ever smaller highlights rather
              than of complete works.{" "}
              <sup className="sup-link">
                <span id="intro-hover-dis27" onClick={(e) => scroll(27)}>
                  27
                </span>
              </sup>{" "}
              Among many early 18<sup>th</sup> century translations of the Roman
              civil war epics only Rowe’s, posthumously published, was complete,
              and only his was of Lucan’s poem.
            </p>
            <p className="introduction-p">
              With the Romantics Lucan’s stock rose again. Shelley praised Lucan
              in his <em>Adonais</em>, and he and Southey are said to have
              preferred him to Virgil.
              <sup className="sup-link">
                <span id="intro-hover-dis28" onClick={(e) => scroll(28)}>
                  28, 29
                </span>
              </sup>{" "}
              But the Romantics never translated Lucan (or, possibly due to
              their emphasis on personal inspiration, much of anything else). By
              the beginning of the nineteenth century, poets (Landor excepted)
              and literary enthusiasts like Gorges and May no longer wrote in
              Latin or often even read it easily; translation had become the
              province of professional academics, and Lucan had fallen off the
              cannon. After Rowe, Lucan was not translated again until the
              Victorian era.{" "}
              <sup className="sup-link">
                <span id="intro-hover-dis30" onClick={(e) => scroll(30)}>
                  30, 31
                </span>
              </sup>{" "}
            </p>

            <hr></hr>
            <h1 className="g1">Early translators of Lucan: Poetics</h1>
            <br />
            <br />
            <br />

            <p className="introduction-p">
              Except for Marlowe, Lucan’s early translators (like Gavin Douglas,
              Virgil’s first English translator a century earlier) used
              Chaucer’s meters – iambic five’s or four’s - and Chaucer’s usual
              rhyme scheme, couplets. None of them took a stanzaic approach,
              although Chaucer certainly supplied a model, as did Spenser's
              recent <em>Faerie Queene</em>. Marlowe chose the relatively new
              blank verse. This meter was developed for his (partial) Aeneid by
              the earl of Surrey, Henry Howard, in the 1540’s. But it was made
              popular on the Elizabethan stage – by Marlowe himself in large
              part. Blank verse gave Marlowe a metrical tool to approximate
              Lucan’s compression and render the complexity and length of Latin
              sentences. It let him work in a meter he used (or, if we like the
              youthful translator theory, <em>would</em> use) as playwright, as
              author indeed of what Ben Jonson called a “mighty line.” Also,
              blank verse was identified already with Latin epic through
              Howard’s <em>Aeneid</em>. In Marlowe’s hands, blank verse proved a
              perfect match for Lucan; in it we hear a spoken voice, as we do in
              the original but not so well, as we shall see, in the rhymed
              translations:
            </p>
            <div className="i-flex-p">
              <p className="introduction-p i-f">
                <span className="i-c-name">LUCAN</span>
                <br />
                Bella per Emathios plus quam civilia campos
                <br />
                Iusque datum sceleri canimus, populumque potentem
                <br />
                In sua victrici conversum viscera dextra,
                <br />
                Cognatasque acies, et rupto foedere regni,
                <br />
                5 Certatum totis concussi viribus orbis
                <br />
                In commune nefas, infestisque obvia signis
                <br />
                Signa, pares aquilas, et pila minantia pilis.
                <br />
                Quis furor, o cives, quae tanta licentia ferri,
                <br />
                Gentibus invisis Latium praebere cruorem?
                <br />
                10 Cumque superba foret Babylon spolianda tropaeis
                <br />
                Ausoniis, umbraque erraret Crassus inulta,
                <br />
                Bella geri placuit nullos habitura triumphos?
                <br />
                Heu quantum terrae potuit pelagique parari
                <br />
                Hoc, quem civiles hauserunt, sanguine, dextrae,
                <br />
                15 Unde venit Titan, et nox ubi sidera condit,
                <br />
                Quaque dies medius flagrantibus aestuat horis,
                <br />
                Et qua bruma, rigens ac nescia vere remitti,
                <br />
                Adstringit Scythico glacialem frigore pontum!
                <br />
                Sub iuga iam Seres, iam barbarus isset Araxes,
                <br />
                20 Et gens si qua iacet nascenti conscia Nilo.
                <br />
                Tunc, si tantus amor belli tibi, Roma, nefandi,
                <br />
                Totum sub Latias leges cum miseris orbem,
                <br />
                In te verte manus: nondum tibi defuit hostis.
              </p>{" "}
              <br />
              <br />
              <p className="introduction-p i-f">
                <span className="i-c-name">MARLOWE</span>
                <br />
                Wars worse than civil on Thessalian plains,
                <br />
                And outrage strangling law, and people strong,
                <br />
                We sing, whose conquering swords their own breasts lancht,
                <br />
                Armies allied, the kingdom's league uprooted,
                <br />
                Th' affrighted world's force bent on public spoil,
                <br />
                Trumpets and drums, like deadly, threatening other,
                <br />
                Eagles alike display'd, darts answering darts,
                <br />
                Romans, what madness, what huge lust of war,
                <br />
                Hath made barbarians drunk with Latin blood?
                <br />
                Now Babylon, proud through our spoil, should stoop,
                <br />
                While slaughter'd Crassus' ghost walks unreveng'd,
                <br />
                Will ye wage war, for which you shall not triumph?
                <br />
                Ay me! O, what a world of land and sea
                <br />
                Might they have won whom civil broils have slain!
                <br />
                As far as Titan springs, where night dims heaven,
                <br />
                I, to the torrid zone where mid-day burns,
                <br />
                And where stiff winter, whom no spring resolves,
                <br />
                Fetters the Euxine Sea with chains of ice;
                <br />
                Scythia and wild Armenia had been yok'd,
                <br />
                And they of Nilus' mouth, if there live any.
                <br />
                Rome, if thou take delight in impious war,
                <br />
                First conquer all the earth, then turn thy force
                <br />
                Against thyself: as yet thou wants not foes.
              </p>
            </div>
            <p className="introduction-p">
              We can forgive Marlowe the trumpets and drums he drags into line
              6. He captures the broken rhythm and uncertain sentence structure
              of the original. Yet in part, perhaps, the effect today of
              Marlowe’s verses depends on what came after. Had not Milton and
              then others used blank verse for epic, I wonder whether it would
              have so familiar, heroic, and English a sound.
            </p>
            <p className="introduction-p">
              The friend and admirer of Jonson, May, had a lifelong passion for
              this poem, correcting his work repeatedly and minutely and adding
              a continuation of it in two languages through the death of Julius
              Caesar.{" "}
              <sup className="sup-link">
                <span id="intro-hover-dis32" onClick={(e) => scroll(32)}>
                  32
                </span>
              </sup>{" "}
              With rhymed couplets May anglicized Lucan; Rowe positively
              Londonized him with pointed, polished heroics in Dryden’s style.
              Both placed an ancient Latin poet comfortably in the bosom of
              Chaucer and his heirs.
            </p>
            <p className="introduction-p">
              Distinguished knight and politician, Gorges, ignoring the advances
              in pentameter made by Drayton, Spenser, and Daniel, inexplicably
              hamstrung his own translation by putting it in quatrameter.
              Sublimity, rage, and high-minded moralizing become difficult when
              terse Latin lines of six feet are reduced to rhyming fours. Set-up
              words become an ever larger proportion of the verse when the meter
              shortens (as, below, the recurrent “did”). And the work-around to
              rhyme “pride” leads off the sense. Did Gorges have in mind
              Chaucer’s use of quatrameter for his translation of the “epic”{" "}
              <em>Romaunt of the Rose</em>? Certainly, Gorges’ work seems to
              come from a previous century:
            </p>
            <div className="i-flex-p">
              <p className="introduction-p i-f">
                <span className="i-c-name">LUCAN</span>
                <br />
                Bella per Emathios plus quam civilia campos
                <br />
                Iusque datum sceleri canimus, populumque potentem
                <br />
                In sua victrici conversum viscera dextra,
                <br />
                Cognatasque acies, et rupto foedere regni,
                <br />
                5 Certatum totis concussi viribus orbis
                <br />
                In commune nefas, infestisque obvia signis
                <br />
                Signa, pares aquilas, et pila minantia pilis.
                <br />
                Quis furor, o cives, quae tanta licentia ferri,
                <br />
                Gentibus invisis Latium praebere cruorem?
              </p>{" "}
              <br />
              <br />
              <p className="introduction-p i-f">
                <span className="i-c-name">GORGES</span>
                <br />
                A More then ciuill warre I sing,
                <br />
                That through th' Emathian fields did ring,
                <br />
                Where reins let loose to head-strong pride,
                <br />
                A potent people did misguide:
                <br />
                Whose conquering hand enrag'd rebounds
                <br />
                On his owne bowels with deepe wounds.
                <br />
                Where Hosts confronting neare alies,
                <br />
                All faith and Empires Lawes defies.
                <br />
                A world of force in faction meetes,
                <br />
                And common guilt like torrents fleets.
                <br />
                Where like infestuous ensignes waue,
                <br />
                The Aegle doth the Aegle braue,
                <br />
                And Pyle against the Pyle doth raue.
                <br />
                Deare Cittizens, what brainsick charmes?
                <br />
                What outrage of disordered armes?
                <br />
                Leades you to feast your enuious foes,
                <br />
                To see you goar'd with your owne blowes?
              </p>
            </div>

            {/* <sup className='sup-link'><a href='#intro1-citations'>33</span></sup> */}

            <div className="pages-i">
              <div className="move-button-i">
                <button onClick={() => setPage("2")} className="i-p-btn i-next">
                  Next Page{" "}
                </button>
              </div>
            </div>
            <br />
            <br />
            <br />
            <hr />
            <div id="intro1-citations">
              <h3>Citations</h3>
            </div>
            <div className="citations-holder">
              <p className="i-citations-list" id="citation-i-1">
                1 Mandelbaum A, The Divine Comedy of Dante Alighieri. A Verse
                Translation with Introduction & Commentary. 3 vols [I. Inferno;
                II. Purgatorio; III. Paradiso], Berkeley, Los Angeles and
                London, University of California Press, 1980–82, vol. I, p. 34
              </p>
              <p className="i-citations-list" id="citation-i-2">
                2 Should Caesar’s <em>De Bello Civili</em> be translated with a
                definite article and Lucan’s without? There is in any case no
                way to preserve the ambiguity in English. Of course, it is a
                matter under discussion whether to call the poem{" "}
                <em>“De Bello Civili”</em> as the early manuscripts tend to do,
                or else “Pharsalia,” following Lucan’s internal reference to his
                own poem as “nostra Pharsalia.”
              </p>
              <p className="i-citations-list" id="citation-i-3">
                3 Stanyhurst R. Thee first foure bookes of Virgil his Aeneis
                translated intoo English heroical verse by Richard Stanyhurst,
                wyth oother poëtical diuises theretoo annexed Available at
                https://quod.lib.umich.edu/e/eebo/A14485<br></br>
                .0001.001?view=toc. Accessed June 5, 2021.
              </p>
              <p className="i-citations-list" id="citation-i-4">
                4 Quoted in Nitchie E. Virgil and the English poets. University
                of Michigan 1919.
              </p>
              <p className="i-citations-list" id="citation-i-5">
                5 Crane O. Virgil's Aeneid: Translated Literally, Line by Line,
                Into English Dactylic Hexameter, by Oliver Crane (1888)
                Available at
                https://www.amazon.co.uk/Virgils-Aeneid-Translated-Literally-Hexameter/dp/1112067736?asin=1112067736&revisionId=&format=4
                <br></br>&depth=1. Accessed December 1 2018.
              </p>
              <p className="i-citations-list" id="citation-i-6">
                6 Ahl F. Virgil’s Aeneid. Oxford University Press 2007.
              </p>
              <p className="i-citations-list" id="citation-i-7">
                7 Ascham R The Scholemaster. Book II. Available at
                http://www.luminarium.org/renascence-editions/ascham2.htm#2.
                Accessed October 10, 2021
              </p>
              <p className="i-citations-list" id="citation-i-8">
                8 Milton J Preface to <em>Paradise Lost</em> 1674. Available at
                https://www.poetryfoundation.org/articles<br></br>
                /69378/introduction-to-paradise-lost accessed October 10, 2021.
              </p>
              <p className="i-citations-list" id="citation-i-9">
                9 Some common English words don’t even <em>have</em> a rhyme
                (e.g. and unfortunately for the translator of epic, “soldier”)
              </p>
              <p className="i-citations-list" id="citation-i-10">
                10 The Civil Wars Between the Houses of Lancaster and York. In
                The complete works in verse and prose of Samuel Daniel, ed. with
                intro., notes and illustr. by A.B. Grosart Volume 2, Oxford
                1885. Available at https://www.google.com/books/edition/
                <br></br>The_complete_works_in_verse_and<br></br>
                _prose_of/hdQUAAAAQAAJ?hl=en. Accessed May 31, 2021.
              </p>
              <p className="i-citations-list" id="citation-i-11">
                11 The Baron’s Wars In The Baron’s Wars, Nymphidea, and other
                poems by Michael Drayton. George Rutledge and Sons, London 1893.
                Available at https://archive.org/details/baronswarsn<br></br>
                ymph01draygoog/page/n6/mode/2up. Accessed May 31, 2021
              </p>
              <p className="i-citations-list" id="citation-i-12">
                12 Lucans First Booke Translated Line for Line. In The Works of
                Christopher Marlowe ed. A.H. Bullen, Vol 3. John C. Nimmo,
                London 1885 Available at https://www.gutenberg.org/files/
                <br></br>21262/21262-h/21262-h.htm#lucan. Accessed May 31, 2021.
              </p>
              <p className="i-citations-list" id="citation-i-13">
                13 Reid LA, An Abortive Pharsalia Translation, Ovidian
                Recusatio, and Elegiac Identity in Turberville's Tragicall
                Tales. Studies in Philology 117(4) Fall 2020; 735.
              </p>
              <p className="i-citations-list" id="citation-i-14">
                14 Publius Ovidius Naso. Amores 1.1-4. Available at
                https://en.amores/1.1 – Wikisource, the free online library.
                Accessed May 31, 2021.
              </p>
              <p className="i-citations-list" id="citation-i-15">
                15 See for example Chaucer’s envoi to his epic Troilus and
                Criseyde:
              </p>
              <div className="i-center-para-cit" id="footnote-1">
                <p className="introduction-p i-special i-ci">
                  Go, litel bok, go, litel my tragedye,
                  <br />
                  ther God thi makere yet, er that he dye
                  <br />
                  so sende might to in som comedye!
                  <br />
                </p>
              </div>
              <p className="i-citations-list" id="citation-i-16">
                16 Reid op cit p 718
              </p>
              <p className="i-citations-list" id="citation-i-17">
                17 Lucans Pharsalia: Containing The Ciuill Warres betweene
                Caesar and Pompey.Written in Latine Heroicall Verse by M.
                Annaeus Lucanus. Translated into English verse by Sir Arthur
                Gorges Knight. London 1614. Available at
                https://quod.lib.umich.edu/e/eebo/<br></br>
                A06411.0001.001?rgn=main;view=fulltext. Accessed May 31, 2021.
              </p>
              <p className="i-citations-list" id="citation-i-18">
                18 Lucans Pharsalia: Or The Civill Warres of Rome, betweene
                Pompey the great, and Iuliu Caesar. The whole tenne Bookes,
                Englished by Thomas May, Esquire.The second Edition, corrected,
                and the Annotations inlarged by the Author.London 1631.
                Available at https://quod.lib.umich.edu/e/eebo/<br></br>
                A06415.0001.001?rgn=main;view=fulltext. Accessed May 31, 2021.
                In addition to his English and Latin continuations of{" "}
                <em>Bello Civili</em> published in 1630 and 1640, respectively,
                May published five editions of his translation: 1626 (the first
                three books), 1631, 1635, and 1650.
              </p>
              <p className="i-citations-list" id="citation-i-19">
                19 Pincus S. 1688 The First Modern Revolution. Yale University
                Press. New Haven 2009.
              </p>
              <p className="i-citations-list" id="citation-i-20">
                20 M. Annaei Lucani Pharsalia, siue, De bello ciuili Caesaris et
                Pompeii libri X. Adiectis ad marginem notis T. Farnabii, quae
                loca obscuriora illustrent. Available at
                https://archive.org/details/bub_gb_s0p<br></br>
                qE167M_wC/page/n3/mode/2up. Accessed May 31, 2021.
              </p>
              <p className="i-citations-list" id="citation-i-21">
                21 Lucan’s Pharsalia Translated into English Verse by Nicholas
                Rowe, Esq. Dublin 1719. Available at
                https://www.google.com/books/edition/_/<br></br>
                IjhWAAAAcAAJ?hl=en&gbpv=1. Accessed May 31, 2021
              </p>
              <p className="i-citations-list" id="citation-i-22">
                22 An Essay upon Statius; or, the First Five Books of Publ.
                Papinius Statius his Thebais done into English Verse by T[homas]
                S[tephens] with the poetick history illustrated. London, 1648.
                Available at
                https://colorado.idm.oclc.org/login?url=https://www-proquest-com.colorado.idm.oclc.org/books/essay-upon-statius-five-first-books-publ-papinius/docview/2240966822/se-2?accountid=14503
              </p>
              <p className="i-citations-list" id="citation-i-23">
                23 The Tenth Satyre of Juvenal, English and Latin, The English
                by Tho Shadwell. With Illustrations upon it. Licensed, London
                1687. Available at https://quod.lib.umich.edu/e/eebo/<br></br>
                A46447.0001.001?rgn=main;view=fulltext. Accessed May 31, 2021.
              </p>
              <p className="i-citations-list" id="citation-i-24">
                24 A good discussion of this debate is in Gillespie S. Statius
                in English, 1648-1767 in Translation and Literature, Vol. 8, No.
                2 (1999), pp. 157-175
              </p>
              <p className="i-citations-list" id="citation-i-25">
                25 The Grameid, an heroic poem descriptive of the campaign of
                viscount Dundee in1689 by James Philips of Almerieclose. Edited
                from the original manuscript and with translation, introduction,
                and notes by the Rev. Alexander D Murdoch, F.S.A. Scot.
                Edinburgh 1888. Available at
                https://digital.nls.uk/scottish-history-society-publications/browse/archive/125646734#?c=0&m=0&s=0&cv=13&
                <br></br>xywh=-386%2C1153%2C2463%2C1525. Accessed May 31, 2021.
              </p>
              <p className="i-citations-list" id="citation-i-26">
                26 A wonderful account of the politics of Lucan’s early
                admirers, detractors, and translators is Norbrook, D. "Lucan,
                Thomas May and the creation of a republican literary culture."
                In Culture and Politics in early Stuart England ed. Sharpe and
                Lake. Stanford University Press, 1993.
              </p>
              <p className="i-citations-list" id="citation-i-27">
                27 By the time Gray translated Statius in 1775 he took on only
                21 lines.
              </p>
              <p className="i-citations-list" id="citation-i-28">
                28 Shelley PB Adonais:46. Available at
                https://www.gutenberg.org/cache/<br></br>
                epub/10119/pg10119-images.html Accessed May 31, 2021
              </p>
              <p className="i-citations-list" id="citation-i-29">
                29 Duff JD. The Civil War (Pharsalia) Loeb Classical Library
                220. Harvard University Press. Cambridge MA 1928. p xiv.
              </p>
              <p className="i-citations-list" id="citation-i-30">
                30 Riley HT. The Pharsalia of Lucan. Literally Translated into
                English with Copious Notes. Henry G. Boehn. London 1853.
              </p>
              <p className="i-citations-list" id="citation-i-31">
                31 Into verse, The Pharsalia of Lucan Translated by the Right
                Hon. Sir Edward Ridley. London 1896. Available at
                http://www.intratext.com/IXT/<br></br>ENG1373/_P1.HTM. Accessed
                May 31, 2021.
              </p>
              <p className="i-citations-list" id="citation-i-32">
                32 An English “Continuation” 1630 and a Latin “Supplementum”
                1640, both in seven books. For a close reading of both see
                Bruère RT. Latin and English Versions of Thomas May's
                Supplementum Lucani. Classical Philology 44(3):145-163. 1949.
              </p>
            </div>
          </div>
        </div>
      </>
    );
  } else if (page === "2") {
    return (
      <div className="intro-container">
        <div className="intro-page">
          <div className="pages-i">
            <div className="move-button-i i-btn-top">
              <button
                onClick={() => setPage("1")}
                className="i-p-btn i-next btn-i-top"
              >
                Previous Page{" "}
              </button>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div className="pages-i">
            <p className="introduction-p">
              A short selection cannot show how well any meter wears after
              thousands of lines, but four beats surely will tire the ear in a
              long poem, even if rhyme is removed and rhythm avoids the iambic.
              <sup className="sup-link special-fn">
                <span id="intro-hover-dis33" onClick={(e) => scroll(33)}>
                  33
                </span>
                <p id="intro-hover-special-wider">
                  <p>
                    33 For example, the metrically daring, dactyl-loving Henry
                    Wadsworth Longfellow (whose <em>Evangeline</em> and{" "}
                    <em>Courtship of Miles Standish</em> are the paradigms of -
                    or possibly the only - long English poems in dactylic
                    hexameter) put his epic Song of <em>Hiawatha</em> –
                    twenty-two books of it – into trochaic quatrameter:
                  </p>
                  <br />
                  <p id="fn-b-center">
                    On the shores of Gitche Gumee, <br />
                    Of the shining Big-Sea-Water,
                    <br />
                    Stood Nokomis, the old woman,
                    <br />
                    Pointing with her finger westward,
                    <br />
                    O'er the water pointing westward,
                    <br />
                    To the purple clouds of sunset,
                    <br />
                    Fiercely the red sun descending
                    <br />
                    Burned his way along the heavens,
                    <br />
                    Set the sky on fire behind him,
                    <br />
                    As war-parties, when retreating,
                    <br />
                    Burn the prairies on their war-trail;
                    <br />
                    <br />
                  </p>
                  <p id="fn33-b">Book IX lines 1-11.</p>
                </p>
              </sup>
            </p>

            <h2 className="introduction-title t-bold">Length</h2>
            <p className="introduction-p">
              Lucan’s proem is 66 lines long and Marlowe, whose frontispiece
              promises a “line for line” translation, is 67. To his great
              credit, May, employing heroic couplets, comes in at 70 despite the
              rigidity of his form. In fact, the ratio of May’s lines to Lucan’s
              is about 1.07 early in the work and 1.15 later – remarkable for a
              rhymer with only five syllables to dispose.
              <sup className="sup-link">
                <span id="intro-hover-dis34" onClick={(e) => scroll(34)}>
                  34
                </span>
              </sup>{" "}
              Here are their translations of Italy’s devastation by civil war in
              Lucan’s proem:
            </p>
            <div className="i-flex-p">
              <p className="introduction-p i-f-s">
                <span className="i-c-name">LUCAN</span>
                <br />
                At nunc semirutis pendent quod moenia tectis
                <br />
                25 Urbibus Italiae, lapsisque ingentia muris
                <br />
                Saxa iacent, nulloque domus custode tenentur
                <br />
                Rarus et antiquis habitator in urbibus errat,
                <br />
                Horrida quod dumis multosque inarata per annos
                <br />
                Hesperia est, desuntque manus <em>poscentibus arvis</em>,<br />
                30 Non tu, Pyrrhe ferox, nec <em>tantis cladibus</em> aucto
                <br />
                Poenus erit: nulli penitus discindere ferro
                <br />
                Contigit: alta sedent civilis vulnera dextrae.
              </p>{" "}
              <br />
              <br />
              <p className="introduction-p i-f-s">
                <span className="i-c-name">MARLOWE</span>
                <br />
                That now the walles of houses halfe rear'd totter,
                <br />
                That rampiers fallen down, huge heapes of stone
                <br />
                Lye in our townes, that houses are abandon'd,
                <br />
                And few live that behold their ancient seats;
                <br />
                Italy many yeares hath lyen until'd,
                <br />
                And choakt with thorns, that greedy earth wants hinds,
                <br />
                Fierce Pirhus neither thou nor Hanniball
                <br />
                Art cause, no forraine foe could so afflict us,
                <br />
                These plagues arise from wreake of civill power.
                <br />
              </p>
              <br />
              <br />
              <p className="introduction-p i-f-s">
                <span className="i-c-name">MAY</span>
                <br />
                But now that walles of halfe fall'n houses so
                <br />
                Hang in Italian Townes, vast stones we see
                <br />
                Of ruin'd walles, whole houses empty be,
                <br />
                And ancient Townes are not inhabited;
                <br />
                That vntill'd Italy's with weedes orespread,
                <br />
                And the neglected Plowes want labouring hands,
                <br />
                Not thou fierce Pyrrhus, nor the Punicke Bands
                <br />
                This waste haue made; no sword could reach so farre,
                <br />
                Deepe pierce the wounds receiu'd in ciuill warre.
                <br />
              </p>
            </div>
            <p className="introduction-p">
              Neither translator quite nails the begging in "poscentibus arvis",
              although Marlowe comes closer (both use “want” in the sense of
              “lack” not of “desire”) or the force of "tantis" in "tantis
              cladibus" (May comes closer). As might have been expected, the
              constraints of rhythm and rhyme lengthened Gorges’ translation to
              twice the original. Rowe, in heroic couplets, is even longer, but
              for a different reason.
            </p>

            <div className="i-flex-p">
              <p className="introduction-p i-f-s">
                <span className="i-c-name">LUCAN</span>
                <br />
                At nunc semirutis pendent quod moenia tectis
                <br />
                25 Urbibus Italiae, lapsisque ingentia muris
                <br />
                Saxa iacent, nulloque domus custode tenentur
                <br />
                Rarus et antiquis habitator in urbibus errat,
                <br />
                Horrida quod dumis multosque inarata per annos
                <br />
                Hesperia est, desuntque manus <em>poscentibus arvis</em>,<br />
                30 Non tu, Pyrrhe ferox, nec <em>tantis cladibus</em> aucto
                <br />
                Poenus erit:nulli penitus discindere ferro
                <br />
                Contigit: alta sedent civilis vulnera dextrae.
                <br />
              </p>{" "}
              <br />
              <br />
              <p className="introduction-p i-f-s">
                <span className="i-c-name">GORGES</span>
                <br />
                But Italy lies now aspoile,
                <br />
                Her Citties leuelld with the soile.
                <br />
                Their loftie walles with breaches torne,
                <br />
                The vacant houses all forlorne.
                <br />
                The Markets vnfrequented beene,
                <br />
                The vntrod streets ore-growne with greene:
                <br />
                The fruitfull faire Hesperian fields,
                <br />
                That nothing now but thistles yeilds,
                <br />
                Cries out for hands to plow and plant,
                <br />
                Her labourers are growne so scant.
                <br />
                This dismall state wherein she stands,
                <br />
                Proceedes not from fierce Pyrrhus hands;
                <br />
                This dismall state wherein she stands,
                <br />
                Proceedes not from fierce Pyrrhus hands;
                <br />
                Nor yet could Hannibals despight
                <br />
                Inflict on vs this wretched plight.
                <br />
                No forraine foes could so preuaile,
                <br />
                Our setled state to rent and quaile:
                <br />
                Th' audacious sword worne by thy side,
                <br />
                Hath hewen in thee these gashes wide.
              </p>
              <br />
              <br />
              <p className="introduction-p i-f-s">
                <span className="i-c-name">ROWE</span>
                <br />
                Behold her fields unknowing of the plow!
                <br />
                Behold her palaces and Tow’rs laid low!
                <br />
                See where overthrown the massy column lyes,
                <br />
                While weeds obscene above the Cornish rise.
                <br />
                Here gaping wide, half-ruin’d walls remain,
                <br />
                There mould’ring pillars nodding roofs sustain.
                <br />
                The landskip once in various veauty spread,
                <br />
                With yellow harvests and the flowry mead,
                <br />
                Displays a wild uncultivated face,
                <br />
                Which bushy brakes and brambles vile disgrace:
                <br />
                No human footstep prints th’ ntrodden Green,
                <br />
                No cheerful maid nor villager is seen.
                <br />
                Even in her cities famous once and great,
                <br />
                Where thousands crowded in the noisie street,
                <br />
                No sound is heard of human voices now,
                <br />
                but whistling winds thro’ empty dwellings blow;
                <br />
                while passing strangers wonder, if they spy
                <br />
                one single melancholy face go by.
                <br />
                Nor Pyrrhus’ sword, nor Cannae’s fatal field,
                <br />
                Such universal desolation yield:
                <br />
                Her impious sons have her worst foes surpassed
                <br />
                And Roman hands have laid Hesperia waste.
              </p>
            </div>
            <h2 className="introduction-title t-bold">
              Reflection of Lucan’s poetics
            </h2>

            <p className="introduction-p">
              Rowe may have been an 18<sup>th</sup> century English poet
              laureate, but he reminds me of a play billboard I saw once at a
              modern poster exhibition. In 1929 in New York there was a
              production of Othello performed in Yiddish, which its billboard
              advised was דורך וויליאם שייקספּיר פֿאַרבעסערט און פאַרגרעסערט
              דורך מאָריס שוואָרץ (by William Shakespeare, improved and enlarged
              by Maurice Schwartz). Samuel Johnson never saw the poster, but he
              picked up an important problem with Rowe’s portrayal of Lucan,
              even as, with uncharacteristic charity and enthusiasm, he hailed
              the translation:
            </p>
            <div className="i-center-para">
              <p className="introduction-p i-special">
                The version of Lucan is one of the greatest productions of
                English poetry; for there
                <br />
                is perhaps none that so completely exhibits the genius and
                spirit of the original. Lucan <br />
                is distinguished by a kind of dictatorial or philosophic
                dignity, rather, as <br />
                Quintilian observes, declamatory than poetical;
                <sup className="sup-link">
                  <span id="intro-hover-dis35" onClick={(e) => scroll(35)}>
                    35
                  </span>
                </sup>{" "}
                full of ambitious morality <br />
                and pointed sentences, comprised in vigorous and animated lines.
                This character <br />
                Rowe has very diligently and successfully preserved. His
                versification, <br />
                which is such as his contemporaries practiced, without any
                attempt at innovation <br />
                or improvement, seldom wants either melody or force.{" "}
                <b>
                  His author’s sense is <br />
                  sometimes a little diluted by additional infusions, and
                  sometimes <br />
                  weakened by too much expansion
                </b>
                . But such faults are to be expected in <br />
                all translations, from the constraint of measures and
                dissimilitude of languages. <br />
                The ‘Pharsalia’ of Rowe deserves more notice than it obtains,
                and as it is <br />
                more read will be more esteemed.{" "}
                <sup className="sup-link">
                  <span id="intro-hover-dis36" onClick={(e) => scroll(36)}>
                    36
                  </span>
                </sup>
              </p>
            </div>
            <p className="introduction-p">
              But it is no praise of Rowe that Johnson liked him, for if there
              is one thing the 18th century cannot be expected to reproduce well
              in translation it is stylistic discord and purposeful ugliness in
              poetry. Yet these things are so important as elements of Lucan’s
              style, weapons he eagerly chose. The opening to his proem is, to
              my ear, grammatically scattered, violently expressed in clashing
              sounds and half-expressed thoughts. Yet to hear Rowe tell it,
              there is nothing much to be upset about. Rowe does not agitate but
              reassures with the easy, polished resolution of each couplet in
              Dryden’s manner, the elongating of Lucan’s punctate thoughts, the
              regularizing of grammar, the absence of “viscera,” “cruorem,”
              “rupto,” the decision not to attempt a tone Lucan was plainly
              seeking with the plosive and dentate alliteration “populumque
              potentem” or the purposeful awkward v’s in “victrici conversum
              viscera.” Rowe is more than reassuring; he is smug.
            </p>
            <div className="i-flex-p">
              <p className="introduction-p i-f">
                <span className="i-c-name">LUCAN</span>
                <br />
                Bella per Emathios plus quam civilia campos
                <br />
                Iusque datum sceleri canimus, populumque potentem
                <br />
                In sua victrici conversum viscera dextra,
                <br />
                Cognatasque acies, et rupto foedere regni,
                <br />
                5 Certatum totis concussi viribus orbis
                <br />
                In commune nefas, infestisque obvia signis
                <br />
                Signa, pares aquilas, et pila minantia pilis.
                <br />
                Quis furor, o cives, quae tanta licentia ferri,
                <br />
                Gentibus invisis Latium praebere cruorem?
              </p>{" "}
              <br />
              <br />
              <p className="introduction-p i-f">
                <span className="i-c-name">ROWE</span>
                <br />
                Emathian plains with slaughter cover’d o’er
                <br />
                And rage unknown to civil wars before,
                <br />
                Establish’d violence, and lawless might,
                <br />
                Avow’d and hallow’d by the name of right;
                <br />
                A race renown’d, the world’s victorious lords,
                <br />
                Turned on themselves with their own hostile swords;
                <br />
                Piles against piles oppos’d in impious fight;
                <br />
                Of blood by friends, by kindred, parents, spilt
                <br />
                One common horror and promiscuous guilt,
                <br />
                A shatter’d world in wild disorter lost,
                <br />
                Leagues, laws, and empire in confusion tost.
                <br />
                Of all the woes which civil discords bring,
                <br />
                And rome o’ercome by Roman arms I sing.
                <br />
                What blind, detested madness could afford
                <br />
                Such horrid licence to the murd’ring sword?
                <br />
                Say, Romans, whence so dire a fury rose,
                <br />
                To glut with Latian blood your barb’rous foes?
                <br />
              </p>
            </div>
            <p className="introduction-p">
              Contrast May, who mitigates as far as possible the perfect
              settled-ness of couplets by employing elisions mid-clause, half
              rhymes, and breaks in the rhythm (“Piles against piles, ‘gainst
              Eagles Eagles fly”) The principle advantage is in sustained
              readability over a long work, but benefit accrues at the passage
              level as well.
            </p>

            <div className="i-flex-p">
              <p className="introduction-p i-f">
                <span className="i-c-name">LUCAN</span>
                <br />
                Bella per Emathios plus quam civilia campos
                <br />
                Iusque datum sceleri canimus, populumque potentem
                <br />
                In sua victrici conversum viscera dextra,
                <br />
                Cognatasque acies, et rupto foedere regni,
                <br />
                5 Certatum totis concussi viribus orbis
                <br />
                In commune nefas, infestisque obvia signis
                <br />
                Signa, pares aquilas, et pila minantia pilis.
                <br />
                Quis furor, o cives, quae tanta licentia ferri,
                <br />
                Gentibus invisis Latium praebere cruorem?
              </p>{" "}
              <br />
              <br />
              <p className="introduction-p i-f">
                <span className="i-c-name">MAY</span>
                <br />
                Warres more then civill on Aemathian plaines
                <br />
                We sing: rage licens'd; where great Rome disdaines
                <br />
                In her owne bowels her victorie us swords;
                <br />
                Where kindred hoasts encounter, all accords
                <br />
                Of Empire broke: where arm'd to impious warre
                <br />
                The strength of all the shaken world from farre
                <br />
                Is met; knowne Ensignes Ensignes doe defie,
                <br />
                Piles against Piles, 'gainst Eagles Eagles fly.
                <br />
                What fury, Countreymen, what madnesse cou'd
                <br />
                Moove you to feast your foes with Roman blood?
              </p>
            </div>
            <p className="introduction-p">
              May’s lines 6 and 7 make Lucan’s meaning in line 5 beautifully
              clear, although he may have shortchanged “in commune nefas” with
              “arm’d to impious war.”
            </p>
            <hr></hr>
            <h1 className="g1">Lucan’s Modern Translators</h1>
            <br />
            <br />
            <br />
            <p className="introduction-p">
              During the last century or so, following the pause of a century
              and a half, a crowd of De Bello translations have been published,
              three of them in prose, starting with Riley’s in 1853.{" "}
              <sup className="sup-link">
                <span id="intro-hover-dis37" onClick={(e) => scroll(38)}>
                  37, 38, 39
                </span>
              </sup>{" "}
              And fully seven in verse, most within only the last thirty years
              by a generation of classicists who came of age, like Lucan
              himself, not during but proximate to a terrible war, many of them
              in the anti-establishment 1960’s.{" "}
              <sup className="sup-link">
                <span id="intro-hover-dis40" onClick={(e) => scroll(43)}>
                  40, 41, 42, 43, 44, 45, 46
                </span>
              </sup>
            </p>
            <p className="introduction-p">
              What is especially remarkable about these seven translations is
              the diversity of approach in rendering Lucan’s meter. It is an
              issue at the heart of each author’s interpretation of{" "}
              <em>De Bello Civili</em>. Edward Ridley’s in 1896 was the first
              verse translation since Rowe’s in 1719. In blank verse, like
              Marlowe’s, Ridley’s translation is more rigidly metrical and less
              adherent to Lucan’s pace. It has been criticized as stodgy, but I
              find it readable, poetic, and, I think, underestimated.
            </p>

            <div className="i-flex-p">
              <p className="introduction-p i-f-s">
                <span className="i-c-name">LUCAN</span>
                <br />
                Quodsi non aliam venturo fata Neroni
                <br />
                Invenere viam, magnoque aeterna parantur
                <br />
                35 Regna deis, coelumque suo servire tonanti
                <br />
                Non nisi saevorum potuit post bella Gigantum:
                <br />
                Iam nihil, o superi, querimur: scelera ipsa nefasque
                <br />
                Hac mercede placent: diros Pharsalia campos
                <br />
                Impleat, et Poeni saturentur sanguine manes;
                <br />
                40 Ultima funesta concurrant proelia Munda.
                <br />
                His, Caesar, Perusina fames, Mutinaeque labores
                <br />
                Accedant fatis; et quas premit aspera classes
                <br />
                Leucas; et ardenti servilia bella sub Aetna:
                <br />
                Multum Roma tamen debet civilibus armis,
                <br />
                45 Quod tibi res acta est.
                <br />
              </p>{" "}
              <br />
              <br />
              <p className="introduction-p i-f-s">
                <span className="i-c-name">MARLOW</span>
                <br />
                But if for Nero, then unborn, the Fates
                <br />
                Would find no other means, and gods not slightly
                <br />
                Purchase immortal thrones, nor Jove joy'd heaven
                <br />
                Until the cruel giants' war was done;
                <br />
                We plain not, heavens, but gladly bear these evils
                <br />
                For Nero's sake: Pharsalia groan with slaughter,
                <br />
                And Carthage souls be glutted with our bloods!
                <br />
                At Munda let the dreadful battles join;
                <br />
                Add, Cæsar, to these ills, Perusian famine,
                <br />
                The Mutin toils, the fleet at Luca[s] sunk,
                <br />
                And cruel field near burning Ætna fought!
                <br />
                Yet Rome is much bound to these civil arms, Which made thee
                emperor
                <br />
              </p>
              <br />
              <br />
              <p className="introduction-p i-f-s">
                <span className="i-c-name">RIDLEY</span>
                <br />
                Yet if the fates could find no other way
                <br />
                For Nero coming, nor the gods with ease
                <br />
                Gain thrones in heaven; and if the Thunderer
                <br />
                Prevailed not till the giant's war was done,
                <br />
                Complaint is silent. For this boon supreme
                <br />
                Welcome, ye gods, be wickedness and crime;
                <br />
                Thronged with our dead be dire Pharsalia's fields,
                <br />
                Be Punic ghosts avenged by Roman blood;
                <br />
                Add to these ills the toils of Mutina;
                <br />
                Perusia's dearth; on Munda's final field
                <br />
                The shock of battle joined; let Leucas' Cape
                <br />
                Shatter the routed navies; servile hands
                <br />
                Unsheath the sword on fiery Etna's slopes:
                <br />
                Still Rome is gainer by the civil war.
                <br />
              </p>
            </div>

            <p className="introduction-p">
              Almost a century later, Widdows published, in 1988, a translation
              quite different in spirit, an attempt to convey Lucan’s dactylic
              hexameter with an equivalent, invented metrical scheme without
              iambs:
            </p>

            <div className="i-center-para">
              <p className="introduction-p i-special i-sp-s">
                <em>
                  “The meter consists of six feet, any of the first five being
                  either a dactyl, a spondee, or a <br />
                  trochee. The last foot is a spondee or a trochee, and the
                  fifth foot is usually a dactyl. The line <br />
                  tends to have a break between words in the third foot though I
                  have been less regular in <br />
                  observing than latin writers were.”{" "}
                  <sup className="sup-link">
                    <span id="intro-hover-dis47" onClick={(e) => scroll(47)}>
                      47
                    </span>
                  </sup>
                </em>
              </p>
            </div>
            <p className="introduction-p">This is how it works in practice:</p>
            <div className="i-flex-p">
              <p className="introduction-p i-f">
                <span className="i-c-name">LUCAN</span>
                <br />
                Bella per Emathios plus quam civilia campos
                <br />
                Iusque datum sceleri canimus, populumque potentem
                <br />
                In sua victrici conversum viscera dextra,
                <br />
                Cognatasque acies, et rupto foedere regni,
                <br />
                5 Certatum totis concussi viribus orbis
                <br />
                In commune nefas, infestisque obvia signis
                <br />
                Signa, pares aquilas, et pila minantia pilis.
                <br />
                Quis furor, o cives, quae tanta licentia ferri,
                <br />
                Gentibus invisis Latium praebere cruorem?
              </p>{" "}
              <br />
              <br />
              <p className="introduction-p i-f">
                <span className="i-c-name">WIDDOWS</span>
                <br />
                War, civil war and worse, fought out on the plains of Thessalia,
                <br />
                Times when injustice reigned and a crime was legally sanctioned,
                <br />
                Times when a powerful race, whose prowess had won it an empire,
                <br />
                Turned its swords on itself, with opposing armies of kinsmen –
                <br />
                This is my theme. I shall tell of the compact of tyrany broken,
                <br />
                Huge contention throughout the shattered world, to engender
                <br />
                Universal guilt, and of spears, and standards and eagles
                <br />
                Facing each other in battle, of Roman arms against Roman.
                <br />
                Fellow countrymen, what a fearful frenzy of blood-lust!
                <br />
                How could you bring yourselves to display to nations that hate
                us
                <br />
                Such fratricidal carnage?
              </p>
            </div>
            <p className="introduction-p">
              Widdows’ modified dactylic hexameter certainly sounds much better
              than the Aeneid translations quoted earlier. But to fill out an
              English line without iambs is to swim up-stream against an
              unforgiving river. Widdows creates work-arounds that lengthen his
              lines sometimes awkwardly (“a crime was legally sanctioned,” “I
              shall tell of the compact of tyranny broken,” “How could you bring
              yourselves to indulge instead in a conflict/Which in the nature of
              things could never lead to a Triumph?”, “Total disaster, whose
              culprit will not be found to be Pyrrhus”). A consequence of his
              meter, as he points out, is that every line begins with a stress.
              This threatens monotony despite alterations in the
              dactyl/spondee/troche content. On the other hand, Widdows’ simple
              diction, rather like Walter’s (see below), plays nicely off the
              elongated lines his rhythm enforces.
            </p>
            <p className="introduction-p">
              Four years later Braund, classical scholar and poet, produced a
              free-verse translation that drew on both those skills. Braund’s
              version is refreshing after the endless bland couplets of Rowe and
              May and Gorges, but it is also occasionally wordy and sometimes
              lacks Lucan’s punch and pith. Compare for example, in sound, in
              sense, in concision creating impact, and in the structured verse
              of a writer in control:
            </p>

            <div className="i-center-para">
              <p className="introduction-p i-special">
                “And of legality conferred on crime we sing, and of a mighty
                people”
                <br />
                <span id="i-s-p-center">with</span>
                <br />
                “Iusque datum sceleri canimus populumque potentem”
              </p>
            </div>
            <p className="introduction-p">
              Her line breaks appear abrupt at times, occurring often after a
              line gone on too long. They produce a jagged effect - seemingly
              intended, as the lack of regular rhythm places line breaks in the
              translator’s control:
            </p>
            <div className="i-center-para">
              <p className="introduction-p i-special">
                “Beneath our yoke already the Seres and
                <br />
                barbarian Araxes could
                <br />
                have come, and the race if it exists, which knows
                <br />
                Nile’s birth.”
                <br />
              </p>
            </div>
            <p className="introduction-p">
              It is essential to an epic that it have not only highs and lows
              but an organizing principle throughout. It is, as I see it, part
              of the beauty of any long work and a measure of its author that
              the range of circumstances and tones can be contained within a
              single meter. Braund’s choice of free verse creates a new poem
              almost with every line, opening her translation to a charge of
              self- indulgence. This is how she begins, with line breaks as
              published:
            </p>
            <div className="i-flex-p">
              <p className="introduction-p i-f">
                <span className="i-c-name">LUCAN</span>
                <br />
                Bella per Emathios plus quam civilia campos
                <br />
                Iusque datum sceleri canimus, populumque potentem
                <br />
                In sua victrici conversum viscera dextra,
                <br />
                Cognatasque acies, et rupto foedere regni,
                <br />
                5 Certatum totis concussi viribus orbis
                <br />
                In commune nefas, infestisque obvia signis
                <br />
                Signa, pares aquilas, et pila minantia pilis.
                <br />
                Quis furor, o cives, quae tanta licentia ferri,
                <br />
                Gentibus invisis Latium praebere cruorem?
                <br />
                10 Cumque superba foret Babylon spolianda tropaeis
                <br />
                Ausoniis, umbraque erraret Crassus inulta,
                <br />
                Bella geri placuit nullos habitura triumphos?
                <br />
                Heu quantum terrae potuit pelagique parari
                <br />
                Hoc, quem civiles hauserunt, sanguine, dextrae,
                <br />
                15 Unde venit Titan, et nox ubi sidera condit,
                <br />
                Quaque dies medius flagrantibus aestuat horis,
                <br />
                Et qua bruma, rigens ac nescia vere remitti,
                <br />
                Adstringit Scythico glacialem frigore pontum!
                <br />
                Sub iuga iam Seres, iam barbarus isset Araxes,
                <br />
                20 Et gens si qua iacet nascenti conscia Nilo.
                <br />
              </p>{" "}
              <br />
              <br />
              <p className="introduction-p i-f">
                <span className="i-c-name">BRAUND</span>
                <br />
                Of wars across Emathia plains, worse than civil wars,
                <br />
                And of legality conferred on crime we sing, and of a mighty
                people
                <br />
                Attacking its own guts with victorious sword-hand,
                <br />
                Of kin facing kin, and, once the pact of tyranny was broken
                <br />
                A conflict waged with all the forces of the shaken world
                <br />
                For universal guilt and of standards ranged in enmity against
                <br />
                Standards of eagles matched and javelins
                <br />
                threatening javelins.
                <br />
                What madness was this, O citizens? What this excessive freedom?
                <br />
                With the sword - to offer Latian blood to hated nations?
                <br />
                And when proud Babylon was there to be stripped of Ausonian
                <br />
                trophies and when Crassus wandered with his ghost
                <br />
                unavenged,
                <br />
                did you choose to wage wars which would bring no
                <br />
                triumphs?
                <br />
                A bitter thought – how much of earth and sea might
                <br />
                Have been won
                <br />
                With this blood shed by the hands of fellow-citizens:
                <br />
                where Titan rises, where Night conceals the star,
                <br />
                where midday blazes with its scorching regions,
                <br />
                where winter, stiff and never eased by spring,
                <br />
                binds with Scythian chill the icey Pontus.
                <br />
                Beneath our yoke already the Seres and
                <br />
                barbarian Araxes could
                <br />
                have come, and the race if it exists, which knows
                <br />
                Nile’s birth.
                <br />
              </p>
            </div>
            <p className="introduction-p">
              Joyce, another poet and classicist, approached the poem she
              prefers to call <em>Pharsalia</em> with a strong sense of its
              having been written – like all epics before the printing press –
              for performance and not solitary reading. Her 1993 goal – through
              shifts in rhythm, paragraphing, use of italics, and marked changes
              in tone – was to recreate the experience of hearing Lucan recite.
              His opinions and digressions she puts in italics to set them off
              from more purely narrative material. Joyce reflects alterations in
              Lucan’s tone through marked changes in meter, different
              tempos/line lengths in free verse. The meter to which her
              translation returns from its tonal highlighting is a rather loose
              iambic hexameter:
            </p>
            <div className="i-flex-p">
              <p className="introduction-p i-f">
                <span className="i-c-name">LUCAN</span>
                <br />
                Bella per Emathios plus quam civilia campos
                <br />
                Iusque datum sceleri canimus, populumque potentem
                <br />
                In sua victrici conversum viscera dextra,
                <br />
                Cognatasque acies, et rupto foedere regni,
                <br />
                5 Certatum totis concussi viribus orbis
                <br />
                In commune nefas, infestisque obvia signis
                <br />
                Signa, pares aquilas, et pila minantia pilis.
                <br />
              </p>{" "}
              <br />
              <br />
              <p className="introduction-p i-f">
                <span className="i-c-name">JOYCE</span>
                <br />
                Wars worse than civil we sing, waged on Emathia’s plains;
                <br />
                Justice given over to crime; a powerful people,
                <br />
                Its conquering sword-hand turned to strike its own vitals;
                <br />
                Kindred front lies; and, after tyrany’s pact had shattered,
                <br />
                All the stricken world’s forces locked in a struggle,
                <br />
                Rivals in evil; standards charging belligerent fellow-
                <br />
                Standards; dueling eagles, and javelin menacing javelin.
                <br />
              </p>
            </div>
            <p className="introduction-p">
              I hear more personal anger in the consonants and rhetoric of
              Lucan’s opening than Joyce does. Here is her italicized version of
              Lucan’s personal voice, although I cannot make out why this
              passage is any more personal than the one above:
            </p>

            <div className="i-flex-p">
              <p className="introduction-p i-f">
                <span className="i-c-name">LUCAN</span>
                <br />
                Sub iuga iam Seres, iam barbarus isset Araxes,
                <br />
                20 Et gens si qua iacet nascenti conscia Nilo.
                <br />
                Tunc, si tantus amor belli tibi, Roma, nefandi,
                <br />
                Totum sub Latias leges cum miseris orbem,
                <br />
                In te verte manus: nondum tibi defuit hostis.
                <br />
              </p>{" "}
              <br />
              <br />
              <p className="introduction-p i-f">
                <span className="i-c-name">JOYCE</span>
                <br />
                <em>
                  By now the Chinese, by now the barbaric Ars too,
                  <br />
                  Would have passed beneath our yoke – and the race (if any
                  there be)
                  <br />
                  Which knows where the Nile is born.
                  <br />{" "}
                  <span className="i-spa-right">
                    Rome, if you have such love
                  </span>
                  <br />
                  Of evil war, wait until you have forced all the globe
                  <br />
                  To submit to Latin laws – then turn your hand
                  <br />
                  Against yourself: never yet have you wanted for foes!
                </em>
              </p>
            </div>
            <p className="introduction-p">
              And this is the sort of change in rhythm that Joyce uses to mark a
              change in tone, such as when Lucan, having excoriated civil war,
              suddenly embraces it:
            </p>

            <div className="i-flex-p">
              <p className="introduction-p i-f">
                <span className="i-c-name">LUCAN</span>
                <br />
                Quodsi non aliam venturo fata Neroni
                <br />
                Invenere viam, magnoque aeterna parantur
                <br />
                35 Regna deis, coelumque suo servire tonanti
                <br />
                Non nisi saevorum potuit post bella Gigantum:
                <br />
                Iam nihil, o superi, querimur:
                <br />
              </p>{" "}
              <br />
              <br />
              <p className="introduction-p i-f">
                <span className="i-c-name">JOYCE</span>
                <br />
                Still, if the Fates have found no other way <br />
                &nbsp;&nbsp;&nbsp;&nbsp;For Nero’s
                <br />
                advent,
                <br />
                &nbsp;&nbsp;if an everlasting dynasty costs
                <br />
                the Gods dear,
                <br />
                &nbsp;&nbsp;if Heaven could not serve her Thunderer
                <br />
                until after
                <br />
                &nbsp;the wars of the savage Earth-born Giants – <br />
                then, O Gods Above
                <br />
                &nbsp;we make no further complaint:
              </p>
            </div>
            <p className="introduction-p">
              Spacing and indenting are as published, presumably reflecting
              Joyce’s sense of how Lucan would have paused or applied emphasis.
              Much of her translation is melodic and does give the vibrant sense
              of a spoken voice. Yet the translator’s choice of meter surely
              owes some debt of fidelity to the poem as written. And{" "}
              <em>this</em> poem was written in a single, continuous, and highly
              traditional meter. That fact is an important truth of its
              composition, of the author’s intention to situate his epic among
              others and therefore of its intended reception. In fragmenting the
              poem’s written form to achieve her aural goals, Joyce gives a
              highly personal reading of Pharsalia and takes considerable
              liberties with its integrity as a written document. Perhaps it is
              a question of trusting the reader. Hamlet was written to be acted
              not read, but we acknowledge and adjust for that as we read it;
              would any prefer a re-write of the play to enhance its stagecraft?
            </p>
            <p className="introduction-p">
              I include Anthony Kline’s 2004 online translation as verse only
              because it is arranged on the page in lines of roughly equal size
              - 15 to 18 syllables without an underlying meter, although with an
              iambic preponderance natural too to English prose. Kline’s is an
              explanatory translation, lengthening Lucan’s references and
              modernizing his geography, but it is a little short on passion in
              sound or sense.
            </p>
            <div className="i-flex-p">
              <p className="introduction-p i-f">
                <span className="i-c-name">LUCAN</span>
                <br />
                Bella per Emathios plus quam civilia campos
                <br />
                Iusque datum sceleri canimus, populumque potentem
                <br />
                In sua victrici conversum viscera dextra,
                <br />
                Cognatasque acies, et rupto foedere regni,
                <br />
                5 Certatum totis concussi viribus orbis
                <br />
                In commune nefas, infestisque obvia signis
                <br />
                Signa, pares aquilas, et pila minantia pilis.
                <br />
                Quis furor, o cives, quae tanta licentia ferri,
                <br />
                Gentibus invisis Latium praebere cruorem?
                <br />
                10 Cumque superba foret Babylon spolianda tropaeis
                <br />
                Ausoniis, umbraque erraret Crassus inulta,
                <br />
                Bella geri placuit nullos habitura triumphos?
                <br />
                Heu quantum terrae potuit pelagique parari
                <br />
                Hoc, quem civiles hauserunt, sanguine, dextrae,
                <br />
                15 Unde venit Titan, et nox ubi sidera condit,
                <br />
                Quaque dies medius flagrantibus aestuat horis,
                <br />
                Et qua bruma, rigens ac nescia vere remitti,
                <br />
                Adstringit Scythico glacialem frigore pontum!
              </p>{" "}
              <br />
              <br />
              <p className="introduction-p i-f">
                <span className="i-c-name">KLINE</span>
                <br />
                I sing of a worse than civil war, of war fought between kinsmen
                <br />
                over Pharsalia’s plains, of wickedness deemed justice; of how
                <br />
                a powerful people turned their own right hands against
                themselves;
                <br />
                of strife within families; how, with the first Triumvirate
                broken,
                <br />
                the forces of the quivering globe contended in mutual
                sinfulness;
                <br />
                standard ranged against standard, eagle matched against eagle,
                <br />
                spear threatening spear. What madness, my countrymen, how wild
                <br />
                that slaughter! With Crassus’ spirit still wandering un-avenged,
                <br />
                while it was yet your duty to strip proud Parthia of Italian
                spoils,
                <br />
                you chose instead to grant our enemies the sight of Roman
                strife,
                <br />
                waging a war that could win no triumphs! With that blood, alas,
                <br />
                spilled by Roman hands, what lands and seas might not have been
                <br />
                won, where night hides the stars and the sun rises, where fiery
                air
                <br />
                parches the south, where the winter’s cold that no spring can
                thaw
                <br />
                freezes the Black Sea in its icy grip!
              </p>
            </div>
            <p className="introduction-p">
              The translation of Matthew Fox in 2012 is written in a free verse
              that imposes on itself, however, a restriction of five or six
              stresses per line in most cases. The result is mainly or purely
              iambic pentameter (“That dragged a shaken world with all its
              strength”) or hexameter (“Of civil wars and worse waged on
              Emathian fields,” “What fury, citizens, what anarchy of iron?”)
              sounding a good deal like Marlowe or Ridley’s blank verse.
            </p>
            <div className="i-flex-p">
              <p className="introduction-p i-f">
                <span className="i-c-name">LUCAN</span>
                <br />
                Bella per Emathios plus quam civilia campos
                <br />
                Iusque datum sceleri canimus, populumque potentem
                <br />
                In sua victrici conversum viscera dextra,
                <br />
                Cognatasque acies, et rupto foedere regni,
                <br />
                5 Certatum totis concussi viribus orbis
                <br />
                In commune nefas, infestisque obvia signis
                <br />
                Signa, pares aquilas, et pila minantia pilis.
                <br />
                Quis furor, o cives, quae tanta licentia ferri,
                <br />
              </p>{" "}
              <br />
              <br />
              <p className="introduction-p i-f">
                <span className="i-c-name">FOX</span>
                <br />
                Of civil wars and worse waged on Emathian fields,
                <br />
                Of crime made law we sing, how a powerful people
                <br />
                Turned on its own heart its conquering hand,
                <br />
                Of ranks of relatives, and a broken pact of rule
                <br />
                That dragged a shaken world with all its strength
                <br />
                Into contention to win a common guilt,
                <br />
                Of standards opposite hostile standards,
                <br />
                Partisan eagles and spears shaking at spears.
                <br />
                What fury, citizens, what anarchy of iron?
                <br />
              </p>
            </div>
            <p className="introduction-p">
              His plan delivers pure iambs so often that the ear anticipates a
              more regular meter and is repeatedly disappointed. Otherwise,
              Fox’s sense of the two languages is acute: “Anarchy of iron” is
              nicely and also exactly “licentia ferri.”
            </p>
            <p className="introduction-p">
              Most recently (2015), Brian Walters published a translation much
              like Kline’s in its indeterminate rhythm. Here is Walter’s opening
              with its five (line 8) six (line 6) and seven (line 4) beat lines
              mostly but not strictly iambic.
            </p>
            <div className="i-flex-p">
              <p className="introduction-p i-f">
                <span className="i-c-name">LUCAN</span>
                <br />
                Bella per Emathios plus quam civilia campos
                <br />
                Iusque datum sceleri canimus, populumque potentem
                <br />
                In sua victrici conversum viscera dextra,
                <br />
                Cognatasque acies, et rupto foedere regni,
                <br />
                5 Certatum totis concussi viribus orbis
                <br />
                In commune nefas, infestisque obvia signis
                <br />
                Signa, pares aquilas, et pila minantia pilis.
                <br />
              </p>{" "}
              <br />
              <br />
              <p className="introduction-p i-f">
                <span className="i-c-name">WALTERS</span>
                <br />
                Wars – we sing – worse than civil, and waged
                <br />
                Across Thessalian plains, crime masquerading as law,
                <br />
                A powerful state disemboweling itself with its own
                <br />
                Victorious right hand, fathers fighting against sons,
                <br />
                And when the tyrannical compact was shattered
                <br />
                All the strength of the shaken world closed in battle
                <br />
                That stained us with guilt forever – with standards
                <br />
                Pitched on hated standards, Eagles matched,
                <br />
                Javelins threatening javelins on either side.
              </p>
            </div>
            <p className="introduction-p">
              A prominent feature of Walter’s translation is his updated
              vocabulary, imposing on Lucan’s work an idiomatic slant, at times
              a diction almost too relaxed. For example:
            </p>
            <div className="i-flex-p">
              <p className="introduction-p i-f">
                <span className="i-c-name">LUCAN</span>
                <br />
                Quis furor, o cives, quae tanta licentia ferri,
                <br />
                Gentibus invisis Latium praebere cruorem?
                <br />
                Cumque superba foret Babylon spolianda tropaeis
                <br />
                Ausoniis,
                <br />
                <br />
                Heu quantum terrae potuit pelagique parari
                <br />
                Hoc, quem Civilis hauserunt, sanguine, dextrae,
                <br />
                <br />
                Quod tibi res acta est. Te, quum, statione peracta,
                <br />
                Astra petes serus, praelati regia coeli
                <br />
                Excipiet, gaudente polo,
                <br />
                <br />
                ...pars aetheris illa sereni
                <br />
                Tota vacet, nullaeque obstent a Caesare nubes.
                <br />
              </p>{" "}
              <br />
              <br />
              <p className="introduction-p i-f">
                <span className="i-c-name">WALTERS</span>
                <br />
                Why this madness, my citizens? Why so ready
                <br />
                With your swords? You offer Latin carnage
                <br />
                To nations we hate when you should have been robbing
                <br />
                Proud Babylon of Ausonian trophies.
                <br />
                <br />
                Don’t you see how much earth and Ocean
                <br />
                This Roman thirst for Roman blood has cost us?
                <br />
                <br />
                And when your time on earth is over, when at last
                <br />
                You seek the stars, heaven will be glad to have you:
                <br />
                Claim whatever seat you want.
                <br />
                <br />
                And I pray your stretch of skyline stays
                <br />
                Clear and empty, that no clouds stand in Caesar’s way.
                <br />
              </p>
            </div>
            <br />
            <br />
            <br />
            <br />
            <hr />
            <br />
            <h1 className="g1">Conclusion</h1>
            <br />
            <br />
            <br />
            <p className="introduction-p">
              Elsewhere, in his own time and since, Lucan was considered among
              Rome’s great poets. But in England, Lucan was “discovered” only in
              the 17<sup>th</sup> century, during an age of poetic ferment. He
              was translated then, it seems, partly for his anti-establishment,
              republican politics, partly for a style that mirrored the creative
              energy of England’s Elizabethan age, and partly for an awakened
              interest in things Roman. But his view of civil war as{" "}
              <em>nefas</em> accorded ill with the martial spirit of the mid and
              late 17<sup>th</sup> century. And by the time its civil wars were
              safely behind it, England had entered its own Augustan age, one in
              which Lucan’s perceived stylistic excesses in rhetoric and
              description made Statius a better choice as the preferred poet of
              Rome’s civil wars. The Romantics admired Lucan’s energy but did
              not translate him. And to the Victorians, the exceptions being
              Riley and Ridley, Lucan was no longer in the canon. With the 20
              <sup>th</sup> century came a vivid reminder of war’s horror, a new
              freedom in verse forms, and a generation of classical scholars and
              poets of whom many were young during the fervent 1960’s in England
              and the United States.
              <br />
              <br />
              Even after so many attempts to capture him in prose and verse
              there is something elusive still about Lucan’s anger, his
              attraction to and mastery of the grotesque in its horror and
              humor; his swings from pithy sententiae to extended hyperbole; the
              defiance of poetic and passionate embrace of societal norms in his
              writing; the love of excess in all its forms (imagining gory
              details or waxing apocalyptic about the zodiac or weather). He is,
              with his gift of apparently spontaneous words, one of those poets
              (Keats another) who plunges into the deep end of poetry and swims
              about effortless and enraptured. A difficult poet for the slow pen
              of the translator.
            </p>

            <div className="move-button-i">
              <button onClick={() => setPage("1")} className="i-p-btn">
                Previous Page{" "}
              </button>
              <br />
              <br />
              <br />
            </div>
            <br />
            <br />
            <hr />
            <br />
            <div className="move-button-test"></div>
          </div>
        </div>

        <div className="comment-holder">
          <CommentSection
            reviews={reviews}
            archive={archive}
            reviewsDataArray={commentDataArray[0]}
            ip={ip}
            id="book-intro1"
            user={user}
          />
        </div>
        <div className="comment--hr-spec">
          <hr />
        </div>
        <div id="intro1-citations">
          <h3>Citations</h3>
        </div>
        <div className="citations-holder citations-page2">
          <p className="i-citations-list" id="citation-i-33">
            33 For example, the metrically daring, dactyl-loving Henry Wadsworth
            Longfellow (whose <em>Evangeline</em> and{" "}
            <em>Courtship of Miles Standish</em> are the paradigms of - or
            possibly the only - long English poems in dactylic hexameter) put
            his epic Song of <em>Hiawatha</em> – twenty-two books of it – into
            trochaic quatrameter:
          </p>
          <div className="i-center-para-cit">
            <p className="introduction-p i-special i-ci">
              On the shores of Gitche Gumee, <br />
              Of the shining Big-Sea-Water,
              <br />
              Stood Nokomis, the old woman,
              <br />
              Pointing with her finger westward,
              <br />
              O'er the water pointing westward,
              <br />
              To the purple clouds of sunset,
              <br />
              Fiercely the red sun descending
              <br />
              Burned his way along the heavens,
              <br />
              Set the sky on fire behind him,
              <br />
              As war-parties, when retreating,
              <br />
              Burn the prairies on their war-trail;
            </p>
          </div>
          <p className="i-citations-list right-float-i-c">
            Book IX lines 1-11.
          </p>
          <p className="i-citations-list" id="citation-i-34">
            34 Buckley E, Paleit E. Thomas May Lucan’s Pharsalia (1627). MHRA
            Tudor & Stuart Translations Vol 18. Cambridge 2020 p 13. Their
            introduction to May is deeply researched, clear, and fascinating.
          </p>
          <p className="i-citations-list" id="citation-i-35">
            35 This Quintilian quote (Institutio Oratorio X.1.91) finds its way
            everywhere, and it was never true. Certainly, Lucan is full of
            declamation but also of imagery, sententiae, plot, character, drama,
            and sound. Moreover, all the major Latin authors were anciently held
            up as sources of rhetoric (Tacitus observes in Dialogus 20 that:
            “Exigitur enim iam ab oratore etiam poeticus decor, non Acii aut
            Pacuvii veterno inquinatus, sed ex Horatii et Virgilii et Lucani
            sacrario prolatus.”). The other major criticism of Lucan, classical
            in origin and then often repeated, is that he is a mere historian.
            The idea seems to have had its origin in Servius: “Lucanus namque
            ideo in numero poetarum esse non meruit, quia videtur historiam
            composuisse, non poema.” (In Tria Virgilii Opera Expositio Aeneid
            1.382). See Sanford EM. Lucan and his Roman Critics. Classical
            Philology 26(3):233-257, 1931.
          </p>
          <p className="i-citations-list" id="citation-i-36">
            36 Rowe.In Johnson S. Lives of the most eminent English poets; with
            critical observations on their works, in three volumes ed. Peter
            Cunningham London 1854. Vol 2 p116. Available at
            https://archive.org/details/livesofmoste<br></br>
            mine02johnuoft/page/116/mode/2up. Accessed May 31, 2021.
          </p>
          <p className="i-citations-list" id="citation-i-37">
            37 Into prose, Riley op. cit
          </p>
          <p className="i-citations-list" id="citation-i-38">
            38 Duff JD. Lucan The Civil War. Loeb Classical Library #220.
            Harvard University Press, Cambridge 1928.
          </p>
          <p className="i-citations-list" id="citation-i-39">
            39 Graves R. Pharsalia. Penguin Books. London 1956.
          </p>
          <p className="i-citations-list" id="citation-i-40">
            40 Ridley E The Pharsalia of Lucan, translated into blank verse.
            Available at http://mcllibrary.org/Pharsalia/book1.html accessed
            September 27 2021.
          </p>
          <p className="i-citations-list" id="citation-i-41">
            41 Widdows PF. Lucan’s Civil War. Indiana University Press.
            Bloomington 1988.
          </p>
          <p className="i-citations-list" id="citation-i-42">
            42 Braund SM. Lucan. Civil War. Oxford University Press. New York
            1992
          </p>
          <p className="i-citations-list" id="citation-i-43">
            43 Joyce JW. Pharsalia. Cornell University Press. Ithaca 1993.
          </p>
          <p className="i-citations-list" id="citation-i-44">
            44 Kline AS. The Civil War: Pharsalia available at
            https://www.poetryintranslation.com<br></br>
            /PITBR/Latin/Lucanhome.php accessed May 30, 2021.
          </p>
          <p className="i-citations-list" id="citation-i-45">
            45 Fox M. Lucan Civil War. Penguin Classics. London 2012.
          </p>
          <p className="i-citations-list" id="citation-i-46">
            46 Walters B. Lucan: Civil War. Hackett. Indianapolis 2015.
          </p>
          <p className="i-citations-list" id="citation-i-47">
            47 Widdows op cit. p xxiii
          </p>
        </div>
      </div>
    );
  }
}

export default Part1;

<p className="introduction-p"></p>;
