import {
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  setDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { Store } from "react-notifications-component";
import { ReactComponent as Add } from "../assets/add.svg";
import { ReactComponent as Close } from "../assets/close.svg";
import { ReactComponent as Light } from "../assets/light.svg";
import { ReactComponent as Loading } from "../assets/loading.svg";
import { ReactComponent as Notification } from "../assets/notification.svg";
import { ReactComponent as SmallerLoading } from "../assets/smallerLoading.svg";
import { ReactComponent as Trash } from "../assets/trash.svg";
import { db } from "../firebase/base";
import Footer from "./footer/Footer";
import Navbar from "./navbar/navbar";
import { timeAgo, toMonthName } from "../utils/commonFunctions";

let titleIndecies = [];
function BookTemplete({ ip, user, book, displayBookName }) {
  const [originalTextBlock, setOriginalTextBlock] = useState(true);
  const [englishTextBlock, setEnglishTextBlock] = useState(false);
  const [editingMode, setEditingMode] = useState(false);
  const [inputContent, setInputContent] = useState({
    original: null,
    english: null,
  });

  const [saving, setSaving] = useState(false);
  const [reviewFormIsOpen, setReviewFormIsOpen] = useState(false);
  const [reviewIsOpen, setReviewIsOpen] = useState(false);
  const [showThisreview, setShowThisreview] = useState(null);
  const [reviewingThisID, setReviewingThisID] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [renderContent, setRenderContent] = useState([]);
  let loading,
    deleting = false;

  /**
   *
   * @param {string} text
   * @param {index} index
   * @returns
   */
  const textFormatter = (text, index) => {
    let inputElement = text;
    if (text.split(" ")[0] === "%title") {
      // SG 08/28/2022 11:48  removing %title by shifting
      inputElement = text
        .split(" ")
        .map((e) => " " + e)
        .slice(1)
        .join("")
        .trim();
      titleIndecies.push(index);
    } else {
      // SG 08/28/2022 11:48  contaions a number as line number
      inputElement = text
        .split(" ")
        .map((e) => " " + e)
        .join("")
        .trim();
    }
    let data = {};
    data[index] = inputElement;
    return [index, inputElement];
  };

  const handleTextChange = (e, index, docType) => {
    let temp = { ...inputContent };
    temp[docType][index] = e.target.value;
    return temp;
  };

  const getDate = () => {
    var date = new Date();
    return {
      date: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
      hour: date.getHours(),
      minute: date.getMinutes(),
      second: date.getSeconds(),
    };
  };

  /**
   *
   * @param {*} text
   * @param {*} index
   * @param {*} docType
   * @returns React Component
   */
  const lineGenerator = (text, index, docType) => {
    let element;
    if (editingMode) {
      // SG 08/28/2022 14:01  editing mode, rendering input fields
      element = (
        <input
          type="text"
          className="w-[500px] text-[1.1em] font-serif font-light leading-10 bg-transparent rounded-md border-[2px] border-transparent hover:border-gray-400 cursor-text"
          value={inputContent[docType][index]}
          onChange={(e) => setInputContent(handleTextChange(e, index, docType))}
        />
      );
      if (!isNaN(text?.split(" ")[0])) {
        element = (
          <div className="">
            <p className="mr-[1em] inline text-[1.2em] text-themeBlue font-serif font-semibold leading-10">
              {text.split(" ")[0]}
            </p>
            <input
              type="text"
              className="w-[500px] nline text-[1.1em] font-serif font-light leading-10 bg-transparent rounded-md border-[2px] border-transparent hover:border-gray-400"
              value={inputContent[docType][index]}
              onChange={(e) =>
                setInputContent(handleTextChange(e, index, docType))
              }
            />
          </div>
        );
      }
    } else {
      // SG 08/28/2022 14:01  regular view, editing not allowed
      element = (
        <p className="text-[1.1em] font-serif font-light leading-10">{text}</p>
      );

      if (!isNaN(text?.split(" ")[0])) {
        element = (
          <div className="">
            <p className="mr-[1em] inline text-[1.2em] text-themeBlue font-serif font-semibold leading-10">
              {text.split(" ")[0]}
            </p>
            <p className="inline text-[1.1em] font-serif font-light leading-10">
              {text
                ?.split(" ")
                .map((e) => " " + e)
                .slice(1)}
            </p>
          </div>
        );
      }
    }
    return element;
  };

  const sendMessage = (message) => {
    Store.addNotification({
      title: "Success",
      message: message,
      type: "success",
      insert: "top",
      container: "top-left",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  };

  const fetchContent = async () => {
    let contentData = {
      original: null,
      english: null,
    };
    let contentInputData = {
      original: null,
      english: null,
    };
    const querySnapshot = await getDocs(collection(db, book));
    querySnapshot.forEach((doc) => {
      doc
        .data()
        .content.split("%en")
        .forEach((e, index) => {
          let data = textFormatter(e.trim(), index);
          contentInputData[doc.id] = {
            ...contentInputData[doc.id],
            [data[0]]: data[1],
          };
        });
    });

    setInputContent(contentInputData);
    return contentData;
  };

  const fetchReviews = async () => {
    let reviewData = [];

    const docRef = doc(db, "newBookReviews", book);
    const docSnap = await getDoc(docRef);

    if (Object.keys(docSnap.data()).length === 0) {
      return [];
    }

    docSnap.data().reviews.forEach((doc) => {
      reviewData.push(doc);
    });
    return reviewData;
  };

  useEffect(() => {
    const fetch = async () => {
      fetchContent();
      const fetchedReviews = await fetchReviews();
      setReviews(fetchedReviews);
      setFetched(true);
    };
    fetch();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (fetched) {
      let renderArr = [];
      let o = 0; // SG 08/29/2022 13:21  index for original text
      let e = 0; // SG 08/29/2022 13:21  index for translation text
      while (inputContent.english[e] || inputContent.original[o]) {
        const index = e;
        if (titleIndecies.includes(e)) {
          // SG 08/28/2022 14:18  creating title compoment
          renderArr.push(
            // SG 08/28/2022 04:10  title of the paragraph
            editingMode ? (
              <div className=" flex justify-center my-[4em]">
                <input
                  className="text-4xl font-semibold font-serif text-themeBlue text-center w-[800px] bg-transparent rounded-md border-[2px] border-transparent hover:border-gray-400 cursor-text "
                  value={inputContent["english"][e]}
                  onChange={(event) =>
                    setInputContent(handleTextChange(event, index, "english"))
                  }
                ></input>
              </div>
            ) : (
              <div className=" flex justify-center my-[4em]">
                <p className="text-4xl font-semibold font-serif text-themeBlue">
                  {inputContent.english[e] && inputContent.english[e]}
                </p>
              </div>
            )
          );
          e++;
        } else {
          let hasReviews = false;
          let reviewCount = 0;
          let thisReview = [];
          for (const review of reviews) {
            if (parseInt(review.id) === e && review.list.length > 0) {
              hasReviews = true;
              reviewCount = review.list.length;
              thisReview = review.list;
              break;
            }
          }
          // SG 08/28/2022 14:55  creating elements for render
          // SG 08/29/2022 11:16  windows width defined here
          renderArr.push(
            // xl:ml-[10vw]
            <div className="grid grid-cols-1 xl:grid-cols-12 justify-center items-center w-screen xl:w-[1400px] xl:pl-[12em] 2xl:pl-0">
              <div
                className={`${
                  originalTextBlock ? "flex xl:block" : "hidden"
                } xl:block col-span-5 justify-center items-center`}
              >
                {lineGenerator(inputContent.original[o], o, "original")}
              </div>
              <div
                className={`${
                  englishTextBlock ? "flex xl:block" : "hidden"
                } xl:block  xl:col-span-5 justify-center items-center`}
              >
                {lineGenerator(inputContent.english[e], e, "english")}
              </div>
              <div className={`hidden xl:flex xl:col-span-2 items-center`}>
                <Add
                  className="text-themeBlue cursor-pointer hover:scale-[1.2] mr-5 transition duration-300 ease-in-out"
                  onClick={(e) => {
                    setReviewIsOpen(false);
                    setReviewFormIsOpen(true);
                    setReviewingThisID(index);
                  }}
                />
                {hasReviews && (
                  <div
                    className="relative cursor-pointer"
                    onClick={(e) => {
                      setReviewingThisID(index);
                      setReviewFormIsOpen(false);
                      setReviewIsOpen(true);
                      setShowThisreview(thisReview);
                    }}
                  >
                    <Notification className="text-themeBlue scale-[1.2]" />
                    <div className="absolute bg-red-500 rounded-full p-[.7em] text-[.9em] text-white top-[-.65em] right-[-.7em] h-[1em] w-[1em] flex justify-center items-center">
                      {reviewCount}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
          e++;
          o++;
        }
      }
      setRenderContent(renderArr);
    }
  }, [fetched, inputContent, englishTextBlock, reviews, editingMode]); // eslint-disable-line

  const handleTextSave = async () => {
    setSaving(true);
    let editedEnglishText = "";
    let editedLatinText = "";

    for (const key in inputContent["english"]) {
      const currentLine = inputContent["english"][key];
      if (titleIndecies.includes(parseInt(key))) {
        // SG 08/28/2022 14:36  this is a title
        editedEnglishText += `%title ${currentLine} %en `;
      } else {
        editedEnglishText += `${currentLine} %en `;
      }
    }
    for (const key in inputContent["original"]) {
      const currentLine = inputContent["original"][key];
      editedLatinText += `${currentLine} %en `;
    }

    await setDoc(doc(db, book, "english"), {
      content: editedEnglishText,
    });
    await setDoc(doc(db, book, "original"), {
      content: editedLatinText,
    });
    await setDoc(doc(db, "booksLastEditedAt", book), {
      date: getDate(),
    });
    setSaving(false);
    sendMessage("Changes have been saved!");
  };

  const handleMobileSwitch = (target) => {
    switch (target) {
      case "original":
        setOriginalTextBlock(true);
        setEnglishTextBlock(false);
        break;
      case "english":
        setOriginalTextBlock(false);
        setEnglishTextBlock(true);
        break;
      default:
        break;
    }
  };

  const setReviewCount = async (count) => {
    const thisBookRef = doc(db, "bookReviewCount", book);
    await setDoc(thisBookRef, { count: increment(count) }, { merge: true });
  };

  const submitReviewHandler = async (id) => {
    if (loading || !document.getElementById("reviewInputContent").value) return;
    loading = true;
    let hasReviews = false;
    let reviewsCopy = [...reviews];
    let targetIndex = 0;
    const payload = {
      name: document.getElementById("reviewInputName").value || "Anonymous",
      content: document.getElementById("reviewInputContent").value,
      date: getDate(),
      ip: ip,
    };
    for (const review of reviewsCopy) {
      if (parseInt(review.id) === reviewingThisID) {
        targetIndex = reviewsCopy.indexOf(review);
        hasReviews = true;
        break;
      }
    }
    if (hasReviews) {
      // SG 08/28/2022 18:47  append new review to the existing array
      reviewsCopy[targetIndex].list.push(payload);
    } else {
      reviewsCopy.push({
        id: reviewingThisID,
        list: [payload],
      });
    }
    // SG 08/31/2022 13:57  increment this book's review count
    await setReviewCount(1);
    await setDoc(doc(db, "newBookReviews", book), { reviews: reviewsCopy });
    const fetchedReviews = await fetchReviews();
    sendMessage("Your suggestion has been recorded!");
    setReviews(fetchedReviews);
    setReviewFormIsOpen(false);
    loading = false;
  };

  const ReviewForm = () => {
    useEffect(() => {
      if (document.getElementById("reviewFormPopup")) {
        document.getElementById("reviewFormPopup").style.transform = "scale(1)";
        document.getElementById("reviewFormPopup").style.transform =
          "translate(-50%, 0)";
      }
    }, []);
    if (!reviewFormIsOpen || !reviewingThisID) return null;

    return (
      <div
        className="fixed z-[1000] left-[50%] top-[25%] -translate-x-[50%] translate-y-[0%] bg-white border-[2px] shadow-xl h-[28em] w-[40em] rounded-[10px] flex flex-col items-center p-8 scale-0 transition-all duration-300 ease-in-out"
        id="reviewFormPopup"
      >
        <div className="w-full flex items-center justify-between">
          <p className="text-2xl text-gray-500 font-medium">
            Leave a Suggestion
          </p>
          <Close
            className="scale-[1.4] hover:scale-[1.6] cursor-pointer"
            onClick={() => setReviewFormIsOpen(false)}
          />
        </div>
        <div className="w-full text-start rounded-lg border-[2px] h-[2.8em] flex items-center pl-5 mt-6">
          <input
            id="reviewInputName"
            placeholder="Name"
            className="bg-transparent flex-1 outline-none text-gray-600"
          />
        </div>
        <div className="w-full rounded-lg border-[2px] h-[10em]  p-3 px-4 mt-6">
          <textarea
            id="reviewInputContent"
            placeholder="Enter your Suggestion"
            className="bg-transparent w-full h-full outline-none text-gray-600 resize-none"
          />
        </div>
        <div
          className="w-full bg-themeBlue text-white flex justify-center items-center h-[3em] rounded-md mt-6 cursor-pointer"
          onClick={submitReviewHandler}
        >
          Submit
        </div>
        <p className="text-[.9em] text-gray-600 mt-5">
          Comments can only be deleted from the network where it was entered.
        </p>
      </div>
    );
  };

  const deleteThisReview = async (date) => {
    if (deleting || !showThisreview) return;
    deleting = true;
    let copiedReviews = [...reviews];
    let copiedCurrentReview = [...showThisreview];
    let indexToBeUpdated = 0;

    let updatedReviews = copiedCurrentReview.filter(
      (review) => review.date !== date
    );

    for (const review of copiedReviews) {
      if (review.id === reviewingThisID) {
        indexToBeUpdated = copiedReviews.indexOf(review);
      }
    }
    if (updatedReviews.length === 0) {
      // SG 08/28/2022 20:50  removing this review slot completly
      copiedReviews = copiedReviews.filter(
        (review) => review.id !== reviewingThisID
      );
    } else {
      copiedReviews[indexToBeUpdated].list = updatedReviews;
    }
    await setDoc(doc(db, "newBookReviews", book), { reviews: copiedReviews });
    const fetchedReviews = await fetchReviews();
    // SG 08/31/2022 13:57  decrement this book's review count
    await setReviewCount(-1);
    sendMessage("Your suggestion has been deleted!");
    setReviews(fetchedReviews);
    setReviewFormIsOpen(false);
    setShowThisreview(null);
    deleting = false;
  };

  const ReviewPopup = () => {
    useEffect(() => {
      if (document.getElementById("reviewPopup")) {
        document.getElementById("reviewPopup").style.transform = "scale(1)";
        document.getElementById("reviewPopup").style.transform =
          "translate(-50%, 0)";
      }
    }, []);
    if (!reviewIsOpen || !reviewingThisID || !showThisreview) return null;

    return (
      <div
        className="fixed z-[1000] left-[50%] top-[30%] -translate-x-[50%] translate-y-[0%] bg-white border-[2px] min-h-[15em] max-h-[30em] w-[40em] rounded-[10px] flex flex-col p-8 shadow-xl scale-0 transition-all duration-300 ease-in-out"
        id="reviewPopup"
      >
        <div className="w-full flex items-center justify-between mb-2 pb-4 border-b-[2px] h-[30%]">
          <p className="text-2xl text-gray-500 font-medium">Suggestions</p>
          <Close
            className="scale-[1.4] hover:scale-[1.6] cursor-pointer"
            onClick={() => {
              setReviewIsOpen(false);
              setShowThisreview(null);
            }}
          />
        </div>
        <div className="w-full flex-1 flex overflow-auto items-center flex-col ">
          {showThisreview.map((review, index) => {
            // SG 11/13/2022 12:39  posted date
            const posted = new Date(
              `${toMonthName(review.date.month)} ${review.date.date}, ${
                review.date.year
              } ${review.date.hour}:${review.date.minute}`
            );
            return (
              <div
                className="w-[85%] h-fit flex justify-center items-start rounded-lg flex-col mt-3 shadow-md mb-3"
                key={index}
              >
                <div className="flex items-center justify-between w-full h-[2.5em] rounded-t-lg bg-gray-100  px-7">
                  <div className="flex items-center justify-center ">
                    <p className="text-[1.1em] font-semibold text-themeBlue">
                      {review.name}
                    </p>
                    <p className="ml-4 text-[.8em] text-gray-700">
                      {timeAgo.format(posted)}
                    </p>
                  </div>
                  {(ip === review.ip || user) && (
                    <Trash
                      className="text-gray-400 hover:scale-[1.2] transition duration-400 ease-in-out cursor-pointer"
                      onClick={() => deleteThisReview(review.date)}
                    />
                  )}
                </div>
                <div className="px-6 py-3">
                  <p className="break-all">{review.content}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  // Loading

  const TipBox = () => {
    return (
      <div className="hidden xl:flex w-[23em] h-[6em] absolute left-[3em] top-[20em]">
        <div className="w-[.8em] bg-themeBlue h-full"></div>
        <div className="flex-1 bg-[#B4C3E5] h-full flex items-center">
          <div className="w-[3em] h-full flex items-center">
            <Light className="text-gray-800" width="50" />
          </div>
          <div className="flex-1 h-full items-center flex-col justify-center pl-3">
            <div className="w-full h-[50%] flex items-center justify-start gap-2">
              <p className="text-gray-700 font-medium">Click</p>
              <Add className="text-gray-700" />
              <p className="text-gray-700 font-medium">To Leave a Suggestion</p>
            </div>
            <div className="w-full h-[50%] flex items-center justify-start gap-2">
              <p className="text-gray-700 font-medium">Click</p>
              <Notification className="text-gray-700" />
              <p className="text-gray-700 font-medium">To Read a Suggestion</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="relative">
      <div className="book-container">
        <Navbar user={user} />
        <ReviewForm />
        <ReviewPopup />

        {user ? (
          <div className="m-10 flex xl:w-fit justify-center items-center flex-col float-right fixed right-0 top-[0] bg-white rounded-lg p-[1em] border-[1px] shadow-lg z-50 ">
            <p className="text-gray-600 font-medium">
              Logged in as Admin (Editing Mode{" "}
              {editingMode ? "Enabled" : "Disabled"})
            </p>
            {editingMode && (
              <button
                onClick={handleTextSave}
                className="rounded-md w-[20em] bg-themeBlue text-white text-center h-[2.5em] font-semibold mt-5"
              >
                {saving ? <SmallerLoading /> : "Save Changes"}
              </button>
            )}
            <button
              onClick={() => {
                setEditingMode(!editingMode);
              }}
              className="rounded-md w-[20em] bg-black text-white text-center h-[2.5em] font-semibold mt-5"
            >
              {editingMode ? "Exit Editing Mode" : "Enter Editing Mode"}
            </button>
          </div>
        ) : (
          <p></p>
        )}
        <div className="w-screen h-[8em] flex justify-center items-center">
          <TipBox />
          <h1 className="text-[2.5em] text-themeBlue font-serif font-semibold">
            {displayBookName}
          </h1>
        </div>
      </div>
      <div
        className="w-screen flex flex-col items-center min-h-[40em]"
        id={book}
      >
        {renderContent.length > 0 ? (
          renderContent.map((line, index) => <div key={index}>{line}</div>)
        ) : (
          <Loading />
        )}
      </div>
      <div className="fixed bottom-0 w-[300px] h-[50px] bg-white rounded-[20px] xl:hidden mr-auto ml-auto right-0 left-0 flex shadow-lg mb-2">
        <div
          className={`flex-1 flex justify-center items-center text-gray-600 font-semibold hover:bg-gray-200 rounded-l-[20px] ${
            originalTextBlock && "bg-gray-200"
          }`}
          onClick={() => handleMobileSwitch("original")}
        >
          Latin
        </div>
        <div
          className={`flex-1 flex justify-center items-center text-gray-600 font-semibold hover:bg-gray-200 rounded-r-[20px] ${
            englishTextBlock && "bg-gray-200"
          }`}
          onClick={() => handleMobileSwitch("english")}
        >
          English
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default BookTemplete;
