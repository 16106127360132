
import { db } from '../../firebase/base';
import { setDoc, doc } from 'firebase/firestore';


const sendPostToArchive = async (archive, reviewContent, reviewToBeDeleted, id) => { 
    const bookNum = id[5];
    var payload = {};
    var newDoc = true;
    var index, i = 0;
    var arrayIndex, j = 0;

   
    archive.forEach((doc) => { //checks if doc for the id already exists in db
        if(doc.id === `book${bookNum}` || doc.id === id) { // archive for the line exists in db
            newDoc = false;
            arrayIndex = j;
        }
        j++;
    });

    if(newDoc) { // create a new doc
        if(id === 'book-intro1'|| id === 'book-intro2') { // new doc for intro
            payload = {id: id, data: [{reviewContent: [reviewToBeDeleted], id: id}]};
        }
        else { // new doc for books
            payload = {id: `book${bookNum}`, data: [{reviewContent: [reviewToBeDeleted], id: id}]};
        }
    }
    else {
        if(id === 'book-intro1'|| id === 'book-intro2') { // new doc for intro
            archive[arrayIndex].data[0].reviewContent.push(reviewToBeDeleted);
            payload = archive[arrayIndex];
        }


        else { // new doc for books
            var newLine = true;
            archive[arrayIndex].data.forEach((line) => {
                if(line.id === id) { // line exists in archive array
                    index = i;
                    newLine = false;
                }   
                i++;
            })

            if(newLine) {
                archive[arrayIndex].data.push({reviewContent: [reviewToBeDeleted], id: id});
                payload = archive[arrayIndex];
            }

            else {
                archive[arrayIndex].data.forEach((item) => {
                    if(item.id === id) {
                        item.reviewContent.push(reviewToBeDeleted);
                    }
                });
                payload = archive[arrayIndex];
            }
        }
    }
    var archiveRef;
    if(id === 'book-intro1'|| id === 'book-intro2') archiveRef = doc(db, 'archive', id);
    else archiveRef = doc(db, 'archive', `book${bookNum}`);
    await setDoc(archiveRef,  payload);
};

export default sendPostToArchive;