import React from "react";
import "./navbar.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import myBackground from "../../assets/longerBackground.svg";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase/base";

function Navbar({ user }) {
  const navigate = useNavigate();
  const [reviewCount, setReviewCount] = useState();

  useEffect(() => {
    let reviewCount = {};
    const getArchives = async () => {
      onSnapshot(collection(db, "bookReviewCount"), (data) => {
        // setReviewCount(data.docs.map((doc) => ({ [doc.id]: doc.data() })));
        data.docs.map((doc) => (reviewCount[doc.id] = doc.data()));
      });
    };
    getArchives();
    setReviewCount(reviewCount);
  }, []);

  return (
    <div className="flex h-[4em] xl:h-[17em] s-screen flex-col">
      <div
        className={`xl:h-[80%] w-full to-blue-700 hidden xl:flex`}
        style={{ backgroundImage: `url(${myBackground})` }}
      >
        <div className="w-fit h-full">
          <img
            alt="pic of .."
            src="img/header2.jpg"
            className="object-cover h-full"
          />
        </div>
        <div className="flex-1 flex justify-center items-center flex-col gap-8">
          <p className="text-[2em] xl:text-[2.7em] font-semibold font-serif text-white">
            LUCAN'S DE BELLO CIVILI
          </p>
          <div className="w-[70%] flex justify-center items-center flex-col">
            <p className="text-[1em] xl:text-[1.3em] font-semibold font-serif text-white">
              A new online English translation with interactive reader
              participation
            </p>
          </div>
        </div>
        <div className="w-fit h-full">
          <img
            alt="pic of .."
            src="img/header1.jpg"
            className="object-cover h-full"
          />
        </div>
      </div>
      {/* mobile nav bar here */}
      <div className="w-full h-[4em] bg-themeBlue lg:hidden "></div>
      {/* mobile nav bar here */}
      <div className="w-full h-[4em] xl:h-[20%] bg-themeBlue items-center justify-around cursor-pointer hidden lg:flex shadow-md">
        <div className="text-white font-serif text-[1.2em] font-semibold ml-[8em] relative group">
          <a href="/">Introduction</a>
          <div className="w-[10em] h-[8em] z-[150] bg-[#5071becc] hidden absolute group-hover:flex left-[-1.8em] top-[1.3em] flex-col justify-center items-center gap-4 border-b-[.5em] border-themeBlue pt-3">
            <p
              className="text-white font-serif text-[1em] font-semibold cursor-pointer hover:scale-[1.05] transition duration-400 ease-in-out"
              onClick={() => navigate("/lucan-in-english")}
            >
              Lucan in English
            </p>
            <p
              className="text-white font-serif text-[1em] font-semibold cursor-pointer hover:scale-[1.05] transition duration-400 ease-in-out"
              onClick={() =>
                navigate("/this-translation", { state: { page: "page2" } })
              }
            >
              This Translation
            </p>
          </div>
        </div>
        <a
          className="relative text-white font-serif text-[1.2em] font-semibold cursor-pointer hover:scale-[1.4] transition duration-400 ease-in-out"
          href="/book1"
        >
          I
          {user && (
            <p className="absolute bg-blue-400 text-white w-[1.3em] h-[1.3em] text-[.9em] text-center rounded-full top-[-.5em] right-[-1.2em]">
              {reviewCount?.book1?.count || 0}
            </p>
          )}
        </a>
        <a
          className="relative text-white font-serif text-[1.2em] font-semibold cursor-pointer hover:scale-[1.4] transition duration-400 ease-in-out"
          href="/book2"
        >
          II
          {user && (
            <p className="absolute bg-blue-400 text-white w-[1.3em] h-[1.3em] text-[.9em] text-center rounded-full top-[-.5em] right-[-1.2em]">
              {reviewCount?.book2?.count || 0}
            </p>
          )}
        </a>
        <a
          className="relative text-white font-serif text-[1.2em] font-semibold cursor-pointer hover:scale-[1.4] transition duration-400 ease-in-out"
          href="/book3"
        >
          {user && (
            <p className="absolute bg-blue-400 text-white w-[1.3em] h-[1.3em] text-[.9em] text-center rounded-full top-[-.5em] right-[-1.2em]">
              {reviewCount?.book3?.count || 0}
            </p>
          )}
          III
        </a>
        <a
          className="relative text-white font-serif text-[1.2em] font-semibold cursor-pointer hover:scale-[1.4] transition duration-400 ease-in-out"
          href="/book4"
        >
          {user && (
            <p className="absolute bg-blue-400 text-white w-[1.3em] h-[1.3em] text-[.9em] text-center rounded-full top-[-.5em] right-[-1.2em]">
              {reviewCount?.book4?.count || 0}
            </p>
          )}
          IV
        </a>
        <a
          className="relative text-white font-serif text-[1.2em] font-semibold cursor-pointer hover:scale-[1.4] transition duration-400 ease-in-out"
          href="/book5"
        >
          {user && (
            <p className="absolute bg-blue-400 text-white w-[1.3em] h-[1.3em] text-[.9em] text-center rounded-full top-[-.5em] right-[-1.2em]">
              {reviewCount?.book5?.count || 0}
            </p>
          )}
          V
        </a>
        <a
          className="relative text-white font-serif text-[1.2em] font-semibold cursor-pointer hover:scale-[1.4] transition duration-400 ease-in-out"
          href="/book6"
        >
          {user && (
            <p className="absolute bg-blue-400 text-white w-[1.3em] h-[1.3em] text-[.9em] text-center rounded-full top-[-.5em] right-[-1.2em]">
              {reviewCount?.book6?.count || 0}
            </p>
          )}
          VI
        </a>
        <a
          className="relative text-white font-serif text-[1.2em] font-semibold cursor-pointer hover:scale-[1.4] transition duration-400 ease-in-out"
          href="/book7"
        >
          {user && (
            <p className="absolute bg-blue-400 text-white w-[1.3em] h-[1.3em] text-[.9em] text-center rounded-full top-[-.5em] right-[-1.2em]">
              {reviewCount?.book7?.count || 0}
            </p>
          )}
          VII
        </a>
        <a
          className="relative text-white font-serif text-[1.2em] font-semibold cursor-pointer hover:scale-[1.4] transition duration-400 ease-in-out"
          href="/book8"
        >
          {user && (
            <p className="absolute bg-blue-400 text-white w-[1.3em] h-[1.3em] text-[.9em] text-center rounded-full top-[-.5em] right-[-1.2em]">
              {reviewCount?.book8?.count || 0}
            </p>
          )}
          VIII
        </a>
        <a
          className="relative text-white font-serif text-[1.2em] font-semibold cursor-pointer hover:scale-[1.4] transition duration-400 ease-in-out"
          href="/book9"
        >
          {user && (
            <p className="absolute bg-blue-400 text-white w-[1.3em] h-[1.3em] text-[.9em] text-center rounded-full top-[-.5em] right-[-1.2em]">
              {reviewCount?.book9?.count || 0}
            </p>
          )}
          IX
        </a>
        <a
          className="relative text-white font-serif text-[1.2em] font-semibold cursor-pointer hover:scale-[1.4] transition duration-400 ease-in-out"
          href="/book10"
        >
          {user && (
            <p className="absolute bg-blue-400 text-white w-[1.3em] h-[1.3em] text-[.9em] text-center rounded-full top-[-.5em] right-[-1.2em]">
              {reviewCount?.book10?.count || 0}
            </p>
          )}
          X
        </a>
        <a
          className="relative text-white font-serif text-[1.2em] font-semibold mr-[8em] hover:scale-[1.1] transition duration-400 ease-in-out"
          href="/download"
        >
          Download
        </a>
      </div>
    </div>
  );
}

export default Navbar;
