import React from "react";
import Navbar from "../navbar/navbar";
import Part2 from "./part2";

function Introduction2({ reviews, ip, user }) {
  return (
    <div>
      <Navbar></Navbar>
      {user ? <p className="login-status">Logged in as Admin</p> : <p></p>}
      <br />
      <h1 className="i-title-intro intro-title-i-2">This Translation</h1>
      <div className="i-changer"></div>
      <Part2 user={user} reviews={reviews} ip={ip} />
    </div>
  );
}

export default Introduction2;
