import React from "react";
import "./part1.css";
import Footer from "../footer/Footer";
import CommentSection from "./CommentSection";

function Part2({ reviews, ip, user }) {
  const commentDataArray = [];
  reviews.forEach((review) => {
    if (review.id === "book-intro2") commentDataArray.push(review);
  });

  return (
    <div className="intro-container">
      <div className="intro-page">
        <p className="introduction-p intro-p2-f">
          My hope is that Latin students like me will find here a translation close
          enough to the text to aid in construing it and that English readers
          will encounter a reasonably poetic facsimile of Lucan.
        </p>
        <p className="introduction-p">
          I chose a six-foot line, longer than usual in English, to allow for
          Latin’s compression. Latin quantitative hexameter offers flexibility
          both by allowing vowel quantities to be (in part) positionally
          determined and in permitting the (almost) free disposition of
          syllables as either spondees or dactyls. Flexibility in meter has the
          effect of enhancing compression, already a characteristic of any
          highly inflected language. Latin’s sparing use of demonstratives and
          and its entire absence of articles also makes the line tighter. My point is that
          a single Latin hexameter can carry a lot of narrative information or
          poetic nuance. A lengthened verse in Latin translation descends from
          one of the early English (but not the even earlier Scottish)
          translator of Virgil. Thomas Phaer used seven feet. Seven was more
          than I could manage, but several early translators adopted Phaer’s
          line, including Chapman for his Homer.{" "}
        </p>
        <p className="introduction-p">
          To fit the meter, syllabic fillers are inevitable in poems and
          especially in their translations. I tried to make these contribute
          tonal enhancements, with an example from Shakespeare in mind:
        </p>
        <div className="i-center-para">
          <p className="introduction-p i-special i-p2c">
            {" "}
            <em>
              Cleopatra is discovered dead on her throne, a snake at her breast.
            </em>
            <br />
            FIRST GUARD: What work is here! Charmian, is this well done?
            <br />
            CHARMIAN: It is well done, and fitting for a princess
            <br />
            Descended of so many royal kings.
            <br />
          </p>
        </div>
        <p className="introduction-p">
          Royal” is filler, but the pleonasm elevates Shakespeare’s line.
        </p>
        <p className="introduction-p">
          I used weak and strong endings, but when an ending is weak the line
          following starts with a stress. The idea was to preserve iambic rhythm
          across lines. Thus, below, the first and third lines have weak
          endings, followed by a stress to begin the second and fourth:
        </p>
        <div className="i-center-para">
          <p className="introduction-p i-special i-p2c-r">
            Let Caesar to these fated things Perusian famine,
            <br />
            Struggle in Mutina add, and ships destroyed
            <br />
            Near bitter Leucas; slave-fought wars by flaming Aetna.
            <br />
            All considered, Rome should thank its civil wars
            <br />
          </p>
        </div>
        <p className="introduction-p">
          This rhythmic plan makes enjambment easier on the ear, an important consideration in English for  any long poem that would unfold a complex plot in the rhythms of poetry. But the same plan is employed also in lines that stand alone:
        </p>
        <div className="i-center-para">
          <p className="introduction-p i-special i-p2c-r">
            And yet, if fate could not contrive a different path <br />
            To Nero’s advent – for eternal kingdoms come
            <br />
            At high price for the gods, and heaven could not serve
            <br />
            The thunderer until a war with savage giants –<br />
            Nothing have we to complain of, O you gods.
            <br />
            No. Crime itself and evil at this price are sweet.
            <br />
          </p>
        </div>
        <p className="introduction-p">
          The last two lines are not enjambed; the first starts with a stress
          after a weak ending. The second is purely iambic. My intention was to tell Lucan’s story in something almost like prose, driven by lines often enjambed so that the meter, half concealed, is mainly at the service of the plot.
        </p>
        <p className="introduction-p">
          In diction I steered away from the archaisms with which Victorian translators
          hoped to create altitude. Whenever possible, I tried to render Lucan’s
          way of putting things, perhaps sometimes flirting with obscurity. 
          An example is in  Book IV (lines 48-49): 
        </p>
        <div className="i-flex-p">
          <p className="introduction-p i-f">
            Hactenus armorum discrimina: cetera bello <br />
            Fata dedit variis incertus motibus aer.
            <br />
          </p>{" "}
          <br />
          <br />
          <p className="introduction-p i-f">
            The arbitration of their arms. As for the rest,
            <br />
            Air with its changing motions gave to war a fate
            <br />
            Uncertain.
          </p>
        </div>
        <p className="introduction-p">
          Should “aer” be translated for clarity as “weather”?
        </p>
        <p className="introduction-p">
          It was my hope to sound  serious but not hide-bound, passionate without bombast, but above all clear, readable, and accurate – accurate in the sense of finding the English that best accommodates both Lucan’s literal phrase and the English idiom its meaning demands.
        </p>
        <p className="introduction-p">
          Where a deep knowledge of the language is wanted, I cannot approach
          the distinguished Latinists who have translated Lucan, from Marlowe to
          modern day scholars like Braund, Joyce, and Widdows. I needed to rely,
          in interpreting difficult passages, on the wisdom and experience of
          prose translators Duff and especially Riley, whose “copious” notes
          were always welcome.
        </p>
        <hr />
        <br />
        <br />
        <br />
      </div>
      <div className="comment-holder">
        <CommentSection
          reviews={reviews}
          reviewsDataArray={commentDataArray[0]}
          ip={ip}
          id="book-intro2"
          user={user}
        />
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Part2;
