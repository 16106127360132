import React from 'react'
import { db } from '../../firebase/base';
import { setDoc, doc, deleteDoc } from 'firebase/firestore';
import sendPostToArchive from './archive';
import { Store } from 'react-notifications-component';

// reviewContent -> all reviews for the line
// reviewToBeDeleted -> one of the review objects in the line to be deleted.
const deleteReview = ({ reviews, containerID, archive, reviewContent, id, reviewToBeDeleted}) => {
  var bookNum = id[5];
  if(id === 'book-intro1') bookNum = '-intro1';
  else if(id === 'book-intro2') bookNum = '-intro2';

  const deletePost = async () => {  
    sendPostToArchive(archive, reviewContent, reviewToBeDeleted, id);
    const reviewRef = doc(db, 'bookReviews', `book${bookNum}`);
    var payload = {...reviewContent};
    
      payload.reviewContent.forEach((item) => {
        var indexToBeDeleted = 0; 
        item.reviews.forEach((review) => {
          if(review === reviewToBeDeleted) { // compares the whole ojects for more accuracy
            payload.reviewCount -= 1;
            item.reviews.splice(indexToBeDeleted, 1);
          }
          indexToBeDeleted += 1;
        });
      });    

      var count = 0; 
      payload.reviewContent.forEach((item) => {
        count += item.reviews.length; 
      });
      payload.reviewCount = count;

      // set payload
      const payloadArray = []; // a docment that hols all reviews for the whole book
      if(payload.reviewCount !== 0) payloadArray.push(payload); // inititlize the paylaodArray with payload
      reviews.forEach((review) => { 
          // chooses the datasets for the same book and load them on the payloadArray
          if(review.id !== payload.id && payload.id.substring(0, 6) === review.id.substring(0, 6))  {
              payloadArray.push(review)
          }
      })

      const payloadObject = {data: payloadArray};
      await setDoc(reviewRef, payloadObject); 
      
    
    Store.addNotification({
      title: "Success!",
      message: "The suggestion has been successfully deleted.",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
  }

    const makeBtn = () => {
        document.querySelector('.review-delete-container').style.display = 'flex';
        document.getElementById('review-delete-btn').style.display = 'block';
        document.getElementById('review-undo-btn').style.display = 'block';
        document.getElementById('review-delete-btn').addEventListener('click', deletePost);

          /* for red border */ 
        // const containers = document.querySelectorAll('.review-card');
        // containers.forEach((item) => item.style.border = 'none');
        document.getElementById(containerID).style.border = '2px solid red';
       
    }
  return (
    <div onClick={makeBtn} className='trash-icon'><svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M19 24h-14c-1.104 0-2-.896-2-2v-16h18v16c0 1.104-.896 2-2 2m-9-14c0-.552-.448-1-1-1s-1 .448-1 1v9c0 .552.448 1 1 1s1-.448 1-1v-9zm6 0c0-.552-.448-1-1-1s-1 .448-1 1v9c0 .552.448 1 1 1s1-.448 1-1v-9zm6-5h-20v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2zm-12-2h4v-1h-4v1z"/></svg></div>
  )
}

export default deleteReview