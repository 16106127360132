import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase/base";
import { jsPDF } from "jspdf";
import { Store } from "react-notifications-component";
import HtmlToRtfBrowser from "html-to-rtf-browser";

export class FileParser {
  constructor(bookNum) {
    this.bookNum = bookNum;
    this.bookContent = null;
    this.titleIndecies = [];
  }

  fetchBookContent = async () => {
    let contentData = {
      original: null,
      english: null,
    };

    const querySnapshot = await getDocs(collection(db, this.bookNum));
    querySnapshot.forEach((doc) => {
      doc
        .data()
        .content.split("%en")
        .forEach((e, index) => {
          let [lineNum, lineContent] = this.textFormatter(e.trim(), index);
          if (lineContent !== "") {
            contentData[doc.id] = {
              ...contentData[doc.id],
              [lineNum]: lineContent,
            };
          }
        });
    });
    this.bookContent = contentData;
  };

  htmlGenerator = (contentType) => {
    let html = `<table>`;
    const originalText = Object.entries(this.bookContent.original);
    const englishText = Object.entries(this.bookContent.english);
    let o = 0;
    let e = 0;

    while (originalText[o] || englishText[e]) {
      html += "<tr>";
      if (this.titleIndecies.includes(e)) {
        // this line is a title
        html += `<td colspan="2"><div class='title-container'><p class='title'>${englishText[e][1]}</p></div></td>`;
        e++;
      } else {
        if (contentType === "both" || contentType === "latin") {
          if (
            o < originalText.length &&
            !isNaN(parseInt(originalText[o][1].split(" ")[0]))
          ) {
            // found a line number in original
            const lineNum = parseInt(originalText[o][1].split(" ")[0]) + " ";
            const text =
              originalText[o][1]
                .split(" ")
                .splice(1, originalText[o][1].length)
                .join(" ") || "";
            html += `<td valign="top"><span class='line-number'>${lineNum}</span><span class='original-text'>${
              o >= originalText.length ? "" : text
            }</span></td>`;
          } else {
            html += `<td valign="top"><span class='original-text'>${
              o >= originalText.length ? "" : originalText[o][1]
            }</span></td>`;
          }
        }
        if (contentType === "both" || contentType === "english") {
          if (
            e < englishText.length &&
            !isNaN(parseInt(englishText[e][1].split(" ")[0]))
          ) {
            // found a line number in english
            const lineNum = parseInt(englishText[e][1].split(" ")[0]) + " ";
            const text =
              englishText[e][1]
                .split(" ")
                .splice(1, englishText[e][1].length)
                .join(" ") || "";
            html += `<td valign="top"><span class='line-number'>${lineNum}</span><span class='english-text'>${
              e >= englishText.length ? "" : text
            }</span></td>`;
          } else {
            html += `<td valign="top"><span class='english-text'>${
              e >= englishText.length ? "" : englishText[e][1]
            }</span></td>`;
          }
        }
        o++;
        e++;
      }
      html += "</tr>";
    }

    html += `</table>`;
    return html;
  };

  exportToWord = async (contentType, filename) => {
    // this long stuff can be removed
    var preHtml = `<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>\
    <head>
      <meta charset='utf-8'>
      <style>
        body {
          margin: -.6in;
        }
        .title {
          font-size: 20px;
        }
        .line-number {
          font-size: 15px;
          font-weight: bold;
        }
        .title-container {
          margin: 30pt 0pt;
          width: 100%;
          color: black;
        }
        table {
          width: 570.5pt;
        }
        .original-text {
          width: 180.5pt;
          text-align: start;
          font-size: 13px;
        }
        .english-text {
          height: 20pt;
          width: 300.5pt;
          font-size: 14px;
          text-align: start;
        }
      </style>
    </head><body>`;

    var postHtml = "</body></html>";
    var mainContent = this.htmlGenerator(contentType);
    var html = preHtml + mainContent + postHtml;
    var blob = new Blob(["\ufeff", html], {
      type: "application/msword",
    });
    var url =
      "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);
    var downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      downloadLink.href = url;
      downloadLink.download = filename;
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
  };

  generateHtmlForPdf = (contentType, additionalStyles) => {
    const originalText = Object.entries(this.bookContent.original);
    const englishText = Object.entries(this.bookContent.english);
    const oLength = originalText.length;
    const eLength = englishText.length;
    let o = 0;
    let e = 0;
    let html = "";

    while (originalText[o] || englishText[e]) {
      html += "<tr>";
      if (this.titleIndecies.includes(e)) {
        // this line is a title
        html += `<td colspan="2"><p style="font-weight: bold; font-family: serif; margin: 10px 0; ${additionalStyles}">${
          englishText[e][1]
        } ${"  "}</p></td>`;
        e++;
      } else {
        if (contentType === "both" || contentType === "latin")
          html += `<td> <p style="font-family: serif;">${
            o >= oLength ? "" : originalText[o][1]
          }</p></td>`;
        if (contentType === "both" || contentType === "english")
          html += `<td><p style="font-family: serif;">${
            e >= eLength ? "" : englishText[e][1]
          }</p></td>`;
        o++;
        e++;
      }
      html += "</tr>";
    }

    return html;
  };

  exportToPdf = async (contentType, filename) => {
    var doc = new jsPDF();
    const header = `
    <html lang="en">
      <body>
        <table style="font-size: 4px; width: 200px;">`;

    var mainContent = this.generateHtmlForPdf(contentType, "font-size: 5px;");

    const footer = `</table></body></html>`;

    await doc.html(header + mainContent + footer, {
      callback: function (doc) {
        doc.save(filename);
      },
      jsPDF: doc,
      margin: [5, 5, 5, 5],
      autoPaging: "text",
      // x: 10, // this shifts the document to the right
      // y: 10,
    });
  };

  exportToTxt = async (contentType, filename) => {
    let text = "";

    if (contentType === "both") {
      for (const keyPair of Object.entries(this.bookContent.original))
        text += `${keyPair[1]}\n`;
      text += "\n\n\n\n\n\n";
      for (const keyPair of Object.entries(this.bookContent.english))
        text += `${keyPair[1]}\n`;
    } else if (contentType === "latin") {
      for (const keyPair of Object.entries(this.bookContent.original))
        text += `${keyPair[1]}\n`;
    } else {
      for (const keyPair of Object.entries(this.bookContent.english))
        text += `${keyPair[1]}\n`;
    }

    let element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  textFormatter = (text, index) => {
    let inputElement = text;
    if (text.split(" ")[0] === "%title") {
      // SG 08/28/2022 11:48  removing %title by shifting
      inputElement = text
        .split(" ")
        .map((e) => " " + e)
        .slice(1)
        .join("")
        .trim();
      this.titleIndecies.push(index);
    } else {
      // SG 08/28/2022 11:48  contaions a number as line number
      inputElement = text
        .split(" ")
        .map((e) => " " + e)
        .join("")
        .trim();
    }
    let data = {};
    data[index] = inputElement;
    return [index, inputElement];
  };

  exportToRtf = async (contentType, filename) => {
    let htmlToRtf = new HtmlToRtfBrowser();
    const header = `
    <html lang="en">
      <body>
        <table style="font-size: 35px; width: 200px;">`;

    var mainContent = this.generateHtmlForPdf(contentType, "font-size: 50px");
    const footer = `</table></body></html>`;

    const rtf = htmlToRtf.convertHtmlToRtf(header + mainContent + footer);
    const blob = new Blob([rtf], { type: "application/rtf;charset=utf-8" });
    const url = window.URL.createObjectURL(blob);

    var downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      downloadLink.href = url;
      downloadLink.download = filename;
      downloadLink.click();
    }
  };

  generateDocument = async (contentType, fileType) => {
    Store.addNotification({
      title: "Success!",
      message: "The document being generated, please wait.",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 8000,
        onScreen: true,
      },
    });
    await this.fetchBookContent();
    const fileName = `${this.bookNum}-${contentType}.${fileType}`;
    if (fileType === "txt") await this.exportToTxt(contentType, fileName);
    else if (fileType === "doc") await this.exportToWord(contentType, fileName);
    else if (fileType === "pdf") await this.exportToPdf(contentType, fileName);
    else if (fileType === "rtf") await this.exportToRtf(contentType, fileName);
  };
}
