import React from "react";
import "./comment.css";
import { useState } from "react";
import { db } from "../../firebase/base";
import { setDoc, doc } from "firebase/firestore";
import { Store } from "react-notifications-component";
import DisplayComments from "./DisplayComments";

function CommentSection({ reviews, ip, reviewsDataArray, id, user }) {
  const [name, setName] = useState("");
  const [comment, setComment] = useState("");

  const getDate = () => {
    var date = new Date();
    return {
      date: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
      hour: date.getHours(),
      minute: date.getMinutes(),
      second: date.getSeconds(),
    };
  };

  // returns true if the IP address exists in the database
  const ipExists = (arr, ip) => {
    var ret = false;
    arr.forEach((item) => {
      if (item.ip === ip) {
        ret = true;
      }
    });
    return ret;
  };

  const insertDataSameIp = (reviewArr, review, name) => {
    reviewArr.forEach((item) => {
      if (item.ip === ip) {
        item.reviews.push({
          name: name,
          review: review,
          date: getDate(),
        });
      }
    });
  };

  const updateDatabase = async (ip, name, review, reviewsDataArray) => {
    var payload;

    if (reviewsDataArray) {
      //row already has reviews=> copy content and add data
      payload = { ...reviewsDataArray };
      payload.reviewCount += 1;
      if (ipExists(payload.reviewContent, ip)) {
        insertDataSameIp(payload.reviewContent, review, name);
      } else {
        // ip does not exist
        const newReview = {
          reviews: [
            {
              name: name,
              review: review,
              date: getDate(),
            },
          ],
          ip: ip,
        };
        payload.reviewContent.push(newReview);
      }
    } else {
      // row does not have reviews yet
      payload = {
        reviewCount: 1,
        reviewContent: [
          {
            ip: ip,
            reviews: [
              {
                name: name,
                review: review,
                date: getDate(),
              },
            ],
          },
        ],
        id: id,
      };
    }

    const payloadArray = [payload];

    // payloadArray.push(payload, ...reviews);
    const payloadObject = { data: payloadArray };

    //Update database
    const reviewRef = doc(db, "bookReviews", id);
    await setDoc(reviewRef, payloadObject);
    Store.addNotification({
      title: "Success!",
      message: "The suggestion has been successfully posted.",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  };

  const submitCommentHandler = () => {
    // store data in database

    if (name.trim() !== "" && comment.trim() !== "") {
      // handle data
      updateDatabase(ip, name, comment, reviewsDataArray);
      document.getElementById("error-p").style.display = "none";
      setName("");
      setComment("");
    } else {
      document.getElementById("error-p").style.display = "block";
      document.getElementById("error-p").innerHTML = "Please fill out the form";
    }
  };

  return (
    <div className="comment-section-container">
      <div className="comment-section-body">
        <div className="comment-tile">
          <span>Add Thoughts</span>
        </div>
        <p id="error-p" className="input-error-p input-e-comments"></p>
        <div className="comment-input-section">
          <input
            placeholder="Name"
            onChange={(e) => setName(e.target.value)}
            value={name}
            type="text"
            className="comment-input-name"
          />
          <textarea
            placeholder="Add a thought..."
            onChange={(e) => setComment(e.target.value)}
            value={comment}
            type="text"
            className="comment-input-comment"
          />
          <button
            type="submit"
            className="comment-submit-btn"
            onClick={submitCommentHandler}
          >
            Submit
          </button>
        </div>
        <div className="comment-hr-container">
          <div>
            <hr />
          </div>
          {reviewsDataArray ? (
            reviewsDataArray.reviewCount === 1 ? (
              <p>{reviewsDataArray.reviewCount} Thought</p>
            ) : (
              <p>{reviewsDataArray.reviewCount} Thoughts</p>
            )
          ) : (
            <p></p>
          )}
        </div>

        <div className="comment-display-seciton">
          <DisplayComments
            reviews={reviews}
            reviewsDataArray={reviewsDataArray}
            ip={ip}
            id={id}
            user={user}
          />
        </div>
      </div>
    </div>
  );
}

export default CommentSection;
