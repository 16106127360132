import React from "react";
import emailjs from "emailjs-com";
import "./contact.css";

function Contact({ open, close, setSuccess }) {
  if (!open) return null;

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_SERVICE,
        process.env.REACT_APP_EMAIL_TEMPLATE,
        e.target,
        process.env.REACT_APP_EMAIL_ID
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    setSuccess(true);
    close();
  };

  return (
    <>
      <div className="modalOverlay contact-modal-overlay" onClick={close}></div>
      <div className="contact-container">
        <h1>Contact the Author</h1>
        <div className="contact-close" onClick={close}>
          <div className="contact-close-relative">
            <div />
            <div />
          </div>
        </div>
        <div className="contact-container-inner">
          <div className="contact-right">
            <form onSubmit={sendEmail}>
              <input
                required
                name="from_name"
                placeholder="Enter your name"
              ></input>
              <input
                type="email"
                required
                name="user_email"
                placeholder="Enter your email"
              ></input>
              <textarea
                required
                name="message"
                placeholder="Enter your message"
              ></textarea>
              <div className="contact-btn-container">
                <button className="contact-submit-btn" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
