import React from "react";
import "../addReviewBtn/modal.css";
import DeleteReview from "../addReviewBtn/deleteReview";
import { timeAgo, toMonthName } from "../../utils/commonFunctions";

function DisplayComments({ reviews, reviewsDataArray, ip, id, user }) {
  const handleChangeDate = (ago, index) => {
    if (ago) {
      document.getElementById(
        "review-date-container-normal-" + id + "-" + index
      ).style.display = "none";
      document.getElementById(
        "review-date-container-ago-" + id + "-" + index
      ).style.display = "flex";
    } else {
      document.getElementById(
        "review-date-container-normal-" + id + "-" + index
      ).style.display = "flex";
      document.getElementById(
        "review-date-container-ago-" + id + "-" + index
      ).style.display = "none";
    }
  };

  const reviewsContainer = [];

  const closeDelete = () => {
    const containers = document.querySelectorAll(".review-card");
    containers.forEach((item) => (item.style.border = "none"));
    document.querySelector(".review-delete-container").style.display = "none";
    document.getElementById("review-delete-btn").style.display = "none";
    document.getElementById("review-delete-btn").outerHTML =
      document.getElementById(
        "review-delete-btn"
      ).outerHTML; /* removes all event listener */
    document.getElementById("review-undo-btn").style.display = "none";
  };

  if (!reviewsDataArray) return null;

  reviewsDataArray.reviewContent.forEach((review) => {
    review.reviews.forEach((item) =>
      reviewsContainer.push({
        item: item,
        canDelete: review.ip === ip ? true : false || user ? true : false,
      })
    );
  });

  var circleClass = "";
  if (id === "intro1" || id === "intro2") circleClass = "circle-btn";

  return (
    <div>
      <div className="modal-content">
        {reviewsContainer.map((review) => {
          const posted = new Date(
            `${toMonthName(review.item.date.month)} ${review.item.date.date}, ${
              review.item.date.year
            } ${review.item.date.hour}:${review.item.date.minute}`
          );
          return (
            <div
              className="review-card"
              id={
                "review-card-container-ago-" +
                id +
                "-" +
                reviewsContainer.indexOf(review)
              }
              key={Math.random()}
            >
              <div className="review-card-top">
                <div className="review-card-info">
                  <div className="review-card-name">
                    <p>{review.item.name}</p>
                  </div>
                  <div
                    className="review-date-container-ago"
                    id={
                      "review-date-container-ago-" +
                      id +
                      "-" +
                      reviewsContainer.indexOf(review)
                    }
                    onClick={() =>
                      handleChangeDate(false, reviewsContainer.indexOf(review))
                    }
                  >
                    <span className="review-year">
                      • {timeAgo.format(posted)}
                    </span>
                  </div>
                  <div
                    className="review-date-container-normal"
                    id={
                      "review-date-container-normal-" +
                      id +
                      "-" +
                      reviewsContainer.indexOf(review)
                    }
                    onClick={() =>
                      handleChangeDate(true, reviewsContainer.indexOf(review))
                    }
                  >
                    <p className="review-year">• {review.item.date.year}</p>
                    <p className="review-month">
                      {" "}
                      {toMonthName(review.item.date.month)}
                    </p>
                    <p className="review-date"> {review.item.date.date}</p>
                  </div>
                </div>
              </div>
              <div className="review-card-body">
                <p key={Math.random()} className="review-p">
                  {review.item.review}
                </p>
                <div className="review-card-delete">
                  {review.canDelete ? (
                    <DeleteReview
                      containerID={`${
                        "review-card-container-ago-" +
                        id +
                        "-" +
                        reviewsContainer.indexOf(review)
                      }`}
                      reviewContent={reviewsDataArray}
                      id={id}
                      reviews={reviews}
                      reviewToBeDeleted={review.item}
                      key={Math.random()}
                    >
                      Delete
                    </DeleteReview>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="review-delete-container">
        <div className={`review-delete-card ${circleClass}`}>
          <button
            className="modal-undo-btn"
            id="review-undo-btn"
            style={{ display: "none" }}
            onClick={closeDelete}
          >
            Undo
          </button>
          <button
            onClick={closeDelete}
            className="modal-delete-btn"
            id="review-delete-btn"
            style={{ display: "none" }}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}

export default DisplayComments;
